import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "App/AuthContext";
import { ButtonStyled, Card, Prompt, TextInputStyled, TextLinkStyled, Title } from "./Authentication.styles";
import ErrorBlock from "commons/components/ErrorBlock";
import styled from "styled-components/macro";
import { isEmailValid } from "commons/util/helpers";
import { MIN_PASSWORD_LENGTH } from "commons/util/constants";
import { Trans, useTranslation } from "react-i18next";

const ErrorBlockStyled = styled(ErrorBlock)`
  margin: -8px 0 24px 0;
`;

const TextInputWithLink = styled(TextInputStyled)`
  .InputBase-label {
    width: 100%;
  }
`;

const PasswordInputLabel = styled.span`
  display: flex;
`;

const TextLink = styled(Link)`
  color: var(--primary-100);
  text-decoration: none;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`;

function Login() {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(""); // to-do: translations
  const [isProcessing, setIsProcessing] = useState(false);
  const { loginUser } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function validateEmail() {
    if (!isEmailValid(email)) {
      setEmailError(t("Authentication.InvalidEmail"));
      return false;
    }
    return true;
  }

  function validatePassword() {
    if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(t("Authentication.InvalidPassword", { length: MIN_PASSWORD_LENGTH }));
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (emailError) setEmailError("");
  }, [email]);

  useEffect(() => {
    if (passwordError) setPasswordError("");
  }, [password]);

  async function handleLogin() {
    setGeneralError("");
    const isValidEmail = validateEmail();
    const isValidPassword = validatePassword();

    if (isValidEmail && isValidPassword) {
      setIsProcessing(true);
      try {
        await loginUser(email, password);
        navigate("/app");
      } catch (e) {
        setGeneralError(e.message);
        setIsProcessing(false);
      }
    }
  }

  return (
    <Card flexDirection="column">
      <Title>{t("Authentication.WelcomeBack")}</Title>
      <ErrorBlockStyled>{generalError}</ErrorBlockStyled>
      <TextInputStyled
        type="email"
        label={t("Authentication.Email")}
        value={email}
        onChange={setEmail}
        onConfirm={handleLogin}
        onBlur={validateEmail}
        errorMessage={emailError}
        autoFocus
        maxLength={128}
      />
      <TextInputWithLink
        type={isPasswordVisible ? "text" : "password"}
        label={
          <PasswordInputLabel>
            <span>{t("Common.Password")}</span>
            <TextLink tabIndex="1" to="/auth/password-reset">
              {t("Authentication.ForgotPassword")}
            </TextLink>
          </PasswordInputLabel>
        }
        value={password}
        onChange={setPassword}
        onConfirm={handleLogin}
        onBlur={validatePassword}
        errorMessage={passwordError}
        leftHint={t("Authentication.MinimumChars", { length: MIN_PASSWORD_LENGTH })}
        rightIcon={isPasswordVisible ? "visibility" : "visibility_off"}
        onRightIconClick={() => setIsPasswordVisible(isVisible => !isVisible)}
        maxLength={128}
      />
      <ButtonStyled size="medium" onClick={handleLogin} loading={isProcessing} fullWidth>
        {t("Authentication.SignIn")}
      </ButtonStyled>

      <Prompt>
        <Trans i18nKey="Authentication.DontHaveAccount">
          Don&apos;t have an account yet?
          <TextLinkStyled to="/auth/register">Create account</TextLinkStyled>
        </Trans>
      </Prompt>
    </Card>
  );
}

export default Login;
