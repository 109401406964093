import React, { createContext, useState, useEffect, useContext } from "react";
import T from "prop-types";
import * as auth from "commons/util/auth";
import callApi from "commons/util/callApi";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  async function getCurrentUser() {
    try {
      const user = await callApi("user");
      setUser(user);
    } catch (err) {
      setUser(null); // not logged in
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  async function loginUser(email, password) {
    await auth.loginUser(email, password);
    await getCurrentUser();
  }

  async function logoutUser() {
    auth.logoutUser();
    setUser(null);
  }

  async function setUserName(newName) {
    await callApi("user/name", "put", { newName }); // try-catch when used
    setUser(oldUser => ({ ...oldUser, nickname: newName }));
  }

  async function setUserEmail(newEmail) {
    await callApi("user/email", "put", { newEmail }); // try-catch when used
    setUser(oldUser => ({ ...oldUser, email: newEmail }));
  }

  async function deleteUser() {
    await callApi("user", "delete"); // try-catch when used
    setUser(null);
  }

  const authValue = {
    user,
    isLoading,
    loginUser,
    logoutUser,
    setUserName,
    setUserEmail,
    deleteUser,
  };

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};
