import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import T from "prop-types";
import callApi from "commons/util/callApi";
import { useRecipeList } from "./RecipeListContext";

export const TagGroupsContext = createContext(null);
export const TagGroupsDispatchContext = createContext(null);

export function useTagGroups() {
  return useContext(TagGroupsContext);
}

export function useTagGroupsDispatch() {
  return useContext(TagGroupsDispatchContext);
}

export function TagGroupsProvider({ children }) {
  const [tagGroups, dispatch] = useReducer(tagGroupsReducer, initialTagGroups);
  const [isLoading, setIsLoading] = useState(true);
  const { recipeList, isLoading: isRecipeListLoading } = useRecipeList();

  useEffect(() => {
    async function fetchTags() {
      try {
        const res = await callApi("tags", "get");
        const tagGroups = res.tagGroups.map((g, i) => ({
          ...g,
          column: i % 2,
          tags: g.tags.map(t => ({
            ...t,
            count: recipeList.filter(r => r.tagIds.includes(t.id)).length,
          })),
        }));
        dispatch({ type: "INIT", tagGroups });
      } finally {
        setIsLoading(false);
      }
    }
    if (!isRecipeListLoading) fetchTags();
  }, [isRecipeListLoading]);

  const tagGroupsContextValue = {
    tagGroups,
    isLoading,
  };

  return (
    <TagGroupsContext.Provider value={tagGroupsContextValue}>
      <TagGroupsDispatchContext.Provider value={dispatch}>{children}</TagGroupsDispatchContext.Provider>
    </TagGroupsContext.Provider>
  );
}

TagGroupsProvider.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};

const initialTagGroups = [];

function tagGroupsReducer(tagGroups, action) {
  switch (action.type) {
    case "INIT": {
      return action.tagGroups;
    }
    case "UPDATE": {
      return action.tagGroups;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
