import React from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Box = styled(Flexbox)`
  padding: 24px;
  border: 1px solid var(--neutral-180);
  background-color: var(--neutral-200);
  border-radius: var(--border-radius-2);
  cursor: pointer;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 1px solid var(--primary-170);
    `}

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 16px;
  }
`;

function Question({ className, title, content, isOpen = false, onClick }) {
  const { t } = useTranslation();
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);

  return (
    <Box className={className} flexDirection="column" isOpen={isOpen} onClick={onClick}>
      <Flexbox justifyContent="space-between" alignItems="center" gap={16}>
        <Typography variant={isScreenMaxSmall ? "body" : "h4"} as="h4">
          {t(title)}
        </Typography>
        <Button variant={isOpen ? "tertiary" : "secondary"} icon={isOpen ? "remove" : "add"} />
      </Flexbox>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0, marginTop: 0 }}
            animate={{ opacity: 1, height: "auto", marginTop: 16 }}
            exit={{ opacity: 0, height: 0, marginTop: 0 }}
          >
            <Typography variant="paragraph">{t(content)}</Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}

Question.propTypes = {
  className: T.string,
  title: T.string,
  content: T.string,
  isOpen: T.bool,
  onClick: T.func,
};

export default Question;
