import React from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Icon from "commons/components/Icon";
import Typography from "commons/components/Typography";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useTranslation } from "react-i18next";

const Box = styled(Flexbox)`
  padding: 16px;
  width: 296px;
  min-height: 166px;
  border: 1px solid transparent;
  border-radius: var(--border-radius-3);
  cursor: pointer;
  background-color: var(--neutral-200);

  &:hover {
    box-shadow: 0px 0px 3px rgba(255, 118, 134, 0.08), 0px 3px 6px 1px rgba(255, 118, 134, 0.12);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid var(--primary-170);
      box-shadow: 0px 0px 3px rgba(255, 118, 134, 0.08), 0px 3px 6px 1px rgba(255, 118, 134, 0.12);
    `}

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: auto;
    width: auto;
    padding: 0;
    margin: 16px 0;
  }
`;

const FeatureIconWrap = styled.div`
  padding: 6px;
  color: var(--primary-100);
  background-color: var(--primary-190);
  border-radius: var(--border-radius-2);
`;

function Feature({ className, icon, title, description, isActive = false, onClick }) {
  const { t } = useTranslation();

  return (
    <Box
      className={className}
      flexDirection="column"
      alignItems="flex-start"
      gap={12}
      isActive={isActive}
      onClick={onClick}
    >
      <Flexbox gap={12} alignItems="center">
        <FeatureIconWrap>
          <Icon name={icon} size={24} />
        </FeatureIconWrap>
        <Typography variant="h4">{t(`Home.${title}`)}</Typography>
      </Flexbox>
      <Typography variant="paragraph">{t(`Home.${description}`)}</Typography>
    </Box>
  );
}

Feature.propTypes = {
  className: T.string,
  icon: T.string,
  title: T.string,
  description: T.string,
  isActive: T.bool,
  onClick: T.func,
};

export default Feature;
