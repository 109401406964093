import { useIsOnline } from "commons/util/useIsOnline";
import { useToast } from "commons/util/useToast";
import { useUpdateEffect } from "commons/util/useUpdateEffect";
import { useTranslation } from "react-i18next";

export function OfflineNotification() {
  const { t } = useTranslation();
  const isOnline = useIsOnline();
  const { showToast } = useToast();

  useUpdateEffect(() => {
    if (!isOnline) {
      showToast(t("App.YouAreOffline"), "info", true);
    } else {
      showToast(t("App.YouAreOnline"), "success", true);
    }
  }, [isOnline]);

  return null;
}
