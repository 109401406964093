import React from "react";
import T from "prop-types";
import ComingSoon from "commons/components/ComingSoon";

function Features({ className }) {
  return <ComingSoon className={className} />;
}

Features.propTypes = {
  className: T.string,
};

export default Features;
