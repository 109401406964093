export const features = [
  {
    id: "recipe-manager",
    icon: "format_list_bulleted",
    title: "RecipeManager",
    description: "RecipeManagerDescription",
    imageURL: "./screenshot-1.webp",
  },
  {
    id: "interactive-cooking",
    icon: "play_arrow",
    title: "InteractiveCooking",
    description: "InteractiveCookingDescription",
    imageURL: "./screenshot-2.webp",
  },
  {
    id: "tags",
    icon: "sell",
    title: "UnlimitedTags",
    description: "UnlimitedTagsDescription",
    imageURL: "./screenshot-3.webp",
  },
  {
    id: "modern-interface",
    icon: "grid_view",
    title: "ModernInterface",
    description: "ModernInterfaceDescription",
    imageURL: "./screenshot-4.webp",
  },
  {
    id: "advanced-filters",
    icon: "filter_alt",
    title: "AdvancedFilters",
    description: "AdvancedFiltersDescription",
    imageURL: "./screenshot-5.webp",
  },
  {
    id: "import-share",
    icon: "share",
    title: "ImportAndShare",
    description: "ImportAndShareDescription",
    imageURL: "./screenshot-6.webp",
  },
];

export const questions = [
  {
    id: 0,
    title: "Home.Question1",
    content: "Home.Answer1",
  },
  {
    id: 1,
    title: "Home.Question2",
    content: "Home.Answer2",
  },
  {
    id: 2,
    title: "Home.Question3",
    content: "Home.Answer3",
  },
  {
    id: 3,
    title: "Home.Question4",
    content: "Home.Answer4",
  },
  {
    id: 4,
    title: "Home.Question5",
    content: "Home.Answer5",
  },
  {
    id: 5,
    title: "Home.Question6",
    content: "Home.Answer6",
  },
];
