import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import T from "prop-types";
import callApi from "commons/util/callApi";

export const PreferencesContext = createContext(null);
export const PreferencesDispatchContext = createContext(null);

export function usePreferences() {
  return useContext(PreferencesContext);
}

export function usePreferencesDispatch() {
  return useContext(PreferencesDispatchContext);
}

export function PreferencesProvider({ children }) {
  const [preferences, dispatch] = useReducer(preferencesReducer, initialPreferences);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchPreferences() {
      try {
        const res = await callApi("settings", "get");
        dispatch({ type: "INIT", preferences: res });
      } finally {
        setIsLoading(false);
      }
    }
    fetchPreferences();
  }, []);

  const preferencesContextValue = {
    preferences,
    isLoading,
  };

  return (
    <PreferencesContext.Provider value={preferencesContextValue}>
      <PreferencesDispatchContext.Provider value={dispatch}>{children}</PreferencesDispatchContext.Provider>
    </PreferencesContext.Provider>
  );
}

PreferencesProvider.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};

const initialPreferences = [];

function preferencesReducer(preferences, action) {
  switch (action.type) {
    case "INIT": {
      return action.preferences;
    }
    case "UPDATE": {
      return action.preferences;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
