import React, { Fragment, useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import Divider from "commons/components/Divider";
import Checkbox from "commons/components/Checkbox";
import Icon from "commons/components/Icon";
import Popover from "commons/components/Popover";
import AddButton from "commons/components/AddButton";
import Loader from "commons/components/Loader";
import Tag from "commons/components/Tag";
import InputBase from "commons/components/InputBase";
import { useTagGroups } from "App/TagGroupsContext";
import EmptyState from "commons/components/EmptyState";
import noTagsImage from "commons/images/emptyState/tags.svg";
import noSearchResultsImage from "commons/images/emptyState/searchResults.svg";
import callLocalStorage from "commons/util/callLocalStorage";
import { useTranslation } from "react-i18next";

const Wrap = styled.div`
  position: relative;
  height: 480px;
  width: 320px;
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 45px);
  overflow: auto;
  background-color: var(--neutral-200);
  border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
`;

const InputBaseStyled = styled(InputBase)`
  .shell {
    height: auto;
    padding: 8px;
  }
`;

const AddButtonStyled = styled(AddButton)`
  padding-top: 4px;
  padding-bottom: 4px;
`;

const SearchWrap = styled.div`
  position: sticky;
  top: 0px;
  border-bottom: 1px solid var(--neutral-180);
  background-color: var(--neutral-200);
  padding: 8px;
`;

const Options = styled.div`
  overflow: auto;
  padding-bottom: 40px;
`;

const Items = styled.div`
  padding: 8px;
`;

const Category = styled(Flexbox)`
  padding: 16px;
  border-radius: var(--border-radius-0);
  cursor: pointer;

  &:hover {
    background-color: var(--neutral-190);
  }
`;

const Item = styled(Flexbox)`
  padding: 8px;
  border-radius: var(--border-radius-1);
  cursor: pointer;

  &:hover {
    background-color: var(--neutral-190);
  }
`;

const CheckboxStyled = styled(Checkbox)`
  align-items: center;

  .check {
    margin-top: 0;
  }
`;

const Count = styled.span`
  color: var(--neutral-120);
`;

const BottomButtons = styled(Flexbox)`
  background-color: var(--neutral-190);
  border-radius: var(--border-radius-0) var(--border-radius-0) var(--border-radius-1) var(--border-radius-1);
  border-top: 1px solid var(--neutral-180);
`;

const BottomButton = styled(Flexbox)`
  padding: 12px 16px;
  font-size: 15px;
  cursor: pointer;
  color: var(--neutral-140);
  border-radius: var(--border-radius-0);

  &:hover {
    background-color: var(--neutral-180);
    color: var(--neutral-120);
  }
`;

function TagInput({ className, selectedTags, setSelectedTags }) {
  const { t } = useTranslation();
  const [searchPhrase, setSearchPhrase] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);

  const { tagGroups, isLoading } = useTagGroups();
  // const navigate = useNavigate();

  function transformTagGroupsIntoTagOptions(tagGroups) {
    return tagGroups
      .map(el => {
        const rememberedIsExpanded = callLocalStorage(`tag-group-panel-expanded-${el.id}`, "get");
        return {
          id: el.id,
          label: el.label,
          items: el.tags
            .filter(t => (searchPhrase ? t.label.toLowerCase().includes(searchPhrase.toLowerCase()) : t))
            .map(t => ({
              id: t.id,
              count: t.count,
              label: <Tag label={t.label} color={t.color} icon={t.icon} />,
              isChecked: selectedTags.map(el => el.id).includes(t.id),
            })),
          isExpanded: rememberedIsExpanded === null ? true : rememberedIsExpanded,
        };
      })
      .filter(el => !!el.items.length);
  }

  useEffect(() => {
    setFilterOptions(transformTagGroupsIntoTagOptions(tagGroups));
  }, [tagGroups, searchPhrase, selectedTags]);

  if (isLoading) {
    return <Loader />;
  }

  function handleCategoryClick(categoryId) {
    const newFilterOptions = [...filterOptions];
    const category = newFilterOptions.find(o => o.id === categoryId);
    category.isExpanded = !category.isExpanded;
    setFilterOptions(newFilterOptions);
    callLocalStorage(`tag-group-panel-expanded-${categoryId}`, "set", category.isExpanded);
  }

  function handleCheckboxClick(tagGroupId, itemId, isChecked) {
    const tag = tagGroups.find(el => el.id === tagGroupId).tags.find(el => el.id === itemId);

    let newSelectedTags = [...selectedTags];
    if (isChecked) {
      newSelectedTags = newSelectedTags.filter(el => el.id !== itemId);
    } else {
      newSelectedTags.push(tag);
    }

    setSelectedTags(newSelectedTags);
  }

  function clearFilter() {
    setSelectedTags([]);
  }

  return (
    <Popover
      placement="bottom-start"
      trigger={
        <InputBaseStyled
          label={t("Tags.Tags")}
          isShell
          value={
            <Flexbox gap={8} alignItems="center" flexWrap="wrap">
              {selectedTags.map(tag => (
                <Tag key={tag.id} icon={tag.icon} label={tag.label} color={tag.color} />
              ))}
              <AddButtonStyled>{t("Tags.AddTag")}</AddButtonStyled>
            </Flexbox>
          }
        />
      }
      content={
        <Wrap>
          <Box className={className}>
            <SearchWrap>
              <TextInput
                placeholder={t("Tags.SearchTags")}
                value={searchPhrase}
                onChange={setSearchPhrase}
                leftIcon="search"
                rightIcon={searchPhrase ? "close" : ""}
                onRightIconClick={() => setSearchPhrase("")}
                maxLength={32}
              />
            </SearchWrap>
            {!!filterOptions.length && (
              <Options>
                {filterOptions.map(option => {
                  return (
                    <Fragment key={option.id}>
                      <Category
                        justifyContent="space-between"
                        onClick={() => handleCategoryClick(option.id)}
                        isExpanded={option.isExpanded}
                      >
                        <Typography variant="body">{option.label}</Typography>
                        <Icon name={option.isExpanded ? "expand_less" : "expand_more"} size={20} />
                      </Category>
                      {option.isExpanded && (
                        <Items>
                          {option.items.map(item => (
                            <Item
                              key={item.id}
                              justifyContent="space-between"
                              onClick={() => handleCheckboxClick(option.id, item.id, item.isChecked)}
                            >
                              <CheckboxStyled label={item.label} isChecked={item.isChecked} />
                              <Count>{item.count}</Count>
                            </Item>
                          ))}
                        </Items>
                      )}
                      <Divider />
                    </Fragment>
                  );
                })}
              </Options>
            )}
            {!isLoading && !filterOptions.length && !searchPhrase && (
              <EmptyState image={noTagsImage} label={t("Tags.NoTags")} height="360px" />
            )}
            {!isLoading && !filterOptions.length && searchPhrase && (
              <EmptyState image={noSearchResultsImage} label={t("Common.NoSearchResults")} height="360px" />
            )}
          </Box>
          <BottomButtons justifyContent="space-between">
            <BottomButton forwardedAs="button" gap={8} flexBasis="50%" alignItems="center" onClick={clearFilter}>
              <Icon name="playlist_remove" size={20} />
              {t("Tags.ClearTags")}
            </BottomButton>
            <BottomButton
              forwardedAs="a"
              gap={8}
              flexBasis="50%"
              alignItems="center"
              href="/app/settings/tags"
              target="_blank"
            >
              <Icon name="open_in_new" size={20} />
              {t("Tags.ManageTags")}
            </BottomButton>
          </BottomButtons>
        </Wrap>
      }
    />
  );
}

TagInput.propTypes = {
  className: T.string,
  selectedTags: T.array,
  setSelectedTags: T.func,
};

export default TagInput;
