import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import T from "prop-types";
import Loader from "commons/components/Loader";

function RouteGuard({ children }) {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <Loader containerHeight="100vh" />;
  }

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  return children;
}

RouteGuard.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};

export default RouteGuard;
