import React, { useMemo, useState } from "react";
import T from "prop-types";
import Timer from "commons/components/Timer";
import { getNextId } from "commons/util/helpers";
import styled, { css } from "styled-components/macro";
import Button from "commons/components/Button";
import Modal from "commons/components/Modal";
import TextInput from "commons/components/TextInput";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import DurationInput from "commons/components/DurationInput";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "commons/components/Icon";

const Ctx = React.createContext();

const Wrap = styled.div`
  ${({ isShown }) =>
    !isShown &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

const AddTimerModal = styled(Modal)`
  width: 360px;
  height: auto;
  padding: 24px;
`;

const TimerButtonWrap = styled.div`
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: var(--z-index-above);
`;

const TimerButton = styled(Button)`
  padding-left: 0;
  padding-right: 4px;

  ${({ isGoingOff }) =>
    isGoingOff &&
    css`
      animation: linear 0.5s infinite alternate flash;
    `}

  @keyframes flash {
    0% {
      box-shadow: 0 0 0 4px var(--primary-100);
    }
    100% {
      box-shadow: none;
    }
  }
`;

const DurationInputStyled = styled(DurationInput)`
  margin-top: 12px;
`;

const AddTimerButton = styled(Button)`
  position: fixed;
  bottom: 24px;
  right: 88px;
  height: 40px;
  z-index: var(--z-index-above);
`;

const Badge = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--primary-100);
  color: var(--neutral-200);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 1px;
`;

const DEFAULT_TIMER_VALUE = 1;

export function TimerProvider({ children }) {
  const { t } = useTranslation();
  const [timers, setTimers] = useState([]);
  const [shouldShowTimers, setShouldShowTimers] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTimerLabel, setNewTimerLabel] = useState("");
  const [newTimerValue, setNewTimerValue] = useState(DEFAULT_TIMER_VALUE);
  const [timersGoingOff, setTimersGoingOff] = useState([]);

  const location = useLocation();

  const isCookingMode = useMemo(() => {
    return location.pathname.includes("cooking-mode");
  }, [location]);

  const showTimer = (value, stepNumber) => {
    const newTimers = [...timers, { id: getNextId(timers), value, label: t("Recipes.Step", { stepNumber }) }];
    setTimers(newTimers);
  };

  const removeTimer = id => {
    const newTimers = timers.filter(t => t.id !== id);
    setTimers(newTimers);
  };

  function handlePillClick() {
    setShouldShowTimers(should => !should);
  }

  function onCloseAddTimerModal() {
    setIsModalOpen(false);
    setNewTimerLabel("");
    setNewTimerValue(DEFAULT_TIMER_VALUE);
  }

  function addCustomTimer() {
    const newTimers = [
      ...timers,
      {
        id: getNextId(timers),
        value: newTimerValue || DEFAULT_TIMER_VALUE,
        label: newTimerLabel || t("App.CustomTimer"),
      },
    ];
    setTimers(newTimers);
  }

  return (
    <Ctx.Provider value={{ showTimer }}>
      {children}

      {(!!timers.length || isCookingMode) && (
        <>
          <TimerButtonWrap>
            <TimerButton
              size="medium"
              variant="secondary"
              onClick={handlePillClick}
              isGoingOff={!!timersGoingOff.length && !shouldShowTimers}
            >
              <Flexbox>
                <Icon size={20} name={shouldShowTimers ? "chevron_left" : "chevron_right"} />
                <Icon size={20} name="timer" />
              </Flexbox>
            </TimerButton>
            {!shouldShowTimers && !!timers.length && <Badge>{timers.length}</Badge>}
          </TimerButtonWrap>

          <Wrap isShown={shouldShowTimers}>
            <AddTimerButton variant="tertiary" icon="add" onClick={() => setIsModalOpen(true)}>
              {t("App.AddTimer")}
            </AddTimerButton>
            {timers.map((timer, i) => (
              <Timer
                key={timer.id}
                index={i}
                value={timer.value}
                label={timer.label}
                removeTimer={() => removeTimer(timer.id)}
                setTimersGoingOff={setTimersGoingOff}
              />
            ))}
          </Wrap>
        </>
      )}

      {isModalOpen && (
        <AddTimerModal
          onClose={onCloseAddTimerModal}
          content={closeModal => (
            <>
              <Typography variant="h3" marginBottom={16}>
                {t("App.AddTimer")}
              </Typography>
              <TextInput
                label={t("App.Label")}
                placeholder={t("App.CustomTimer")}
                value={newTimerLabel}
                onChange={setNewTimerLabel}
                onConfirm={addCustomTimer}
                maxLength={48}
              />
              <Flexbox justifyContent="flex-start">
                <DurationInputStyled
                  label={t("App.Duration")}
                  value={newTimerValue}
                  onChange={setNewTimerValue}
                  onConfirm={addCustomTimer}
                  withSeconds
                />
              </Flexbox>
              <Flexbox justifyContent="flex-end" marginTop={24} gap={8}>
                <Button onClick={closeModal} variant="tertiary">
                  {t("Common.Cancel")}
                </Button>
                <Button
                  onClick={() => {
                    addCustomTimer();
                    closeModal();
                  }}
                >
                  {t("Common.Add")}
                </Button>
              </Flexbox>
            </>
          )}
        />
      )}
    </Ctx.Provider>
  );
}

TimerProvider.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};

export const useTimer = () => React.useContext(Ctx);
