import React, { useEffect } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
// import { getYear } from "date-fns";
import Logo from "commons/components/Logo";
// import Typography from "commons/components/Typography";
import i18next from "i18next";
import Popover from "commons/components/Popover";
import Icon from "commons/components/Icon";
import OptionList from "commons/components/OptionList";
import Button from "commons/components/Button";
import { useTranslation } from "react-i18next";

const Box = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 24px 32px;
  background-color: var(--primary-190);
  color: var(--neutral-200);
`;

const LanguageButton = styled(Button)`
  /* typical dropdown button styling */
  div {
    display: flex;
    padding-right: 0;
    gap: 2px;
  }
`;

const IconLeft = styled(Icon)`
  margin-right: 4px;
`;

// const RightWrap = styled.div`
//   width: 1024px;
//   max-width: 100%;
//   margin: 0 auto;
//   padding: 0 32px;
// `;

// const Contact = styled(Typography)`
//   margin: 24px 0;
// `;

// const AnchorLink = styled.a`
//   font-weight: 600;
//   text-decoration: underline;

//   &:hover {
//     text-decoration: underline;
//     color: red;
//   }
// `;

// const Copyright = styled(Typography)`
//   margin: 24px 0;
// `;

function Footer({ className }) {
  const { t } = useTranslation();

  const currentLanguage = i18next.resolvedLanguage;

  useEffect(() => {
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  const locales = {
    en: t("Languages.English"),
    pl: t("Languages.Polish"),
    // si: t("Languages.Slovenian"),
  };

  function changeLanguage(lang) {
    i18next.changeLanguage(lang);
  }

  return (
    <Box className={className}>
      <Logo />

      <Popover
        trigger={
          <LanguageButton variant="tertiary">
            <IconLeft name="language" size={16} /> {locales[currentLanguage]} <Icon name="arrow_drop_down" size={16} />
          </LanguageButton>
        }
        content={closePopover => (
          <OptionList
            options={Object.entries(locales).map(([key, value]) => ({
              id: key,
              label: value,
            }))}
            selectedOptionId={currentLanguage}
            onChange={changeLanguage}
            closePopover={closePopover}
          />
        )}
        placement="bottom-start"
      />
      {/* <RightWrap>
        <Contact variant="body">
          Questions? Email us at <AnchorLink href="#">support@cookyourgoose.eu</AnchorLink>
        </Contact>
        <Copyright variant="label" color="neutral-160">
          Copyright ©{getYear(new Date())} Feel Goose, Inc. All rights reserved.
        </Copyright>
      </RightWrap> */}
    </Box>
  );
}

Footer.propTypes = {
  className: T.string,
};

export default Footer;
