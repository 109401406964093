import { useMemo } from "react";
import T from "prop-types";
import callApi from "commons/util/callApi";
import { useToast } from "commons/util/useToast";
import { useNavigate } from "react-router-dom";
import { useRecipeList, useRecipeListDispatch } from "App/RecipeListContext";
import { useLocationBase } from "commons/util/useLocationBase";
import { useGroceryList, useGroceryListDispatch } from "App/GroceryListContext";
import { useTranslation } from "react-i18next";

export const useRecipeActions = (recipeId, onClose) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const locationBase = useLocationBase();

  const { recipeList } = useRecipeList();
  const { groceryList } = useGroceryList();

  const recipe = useMemo(() => {
    return recipeList.find(el => el.id === recipeId) || {};
  }, [recipeId, recipeList]);

  const dispatch = useRecipeListDispatch();
  const dispatchGroceryList = useGroceryListDispatch();

  const { neverCooked, isFavorite, ingredients } = recipe;
  const hasIngredients = !!ingredients?.filter(ingredient => ingredient.label && !ingredient.isCategory).length;
  const isAddedToGroceryList = groceryList.map(el => el.recipeId).includes(recipeId);

  async function duplicateRecipe() {
    const newRecipe = { ...recipe, title: `${recipe.title} ${t("Recipes.Copy")}` };
    delete newRecipe.id;
    try {
      const res = await callApi("recipes", "post", newRecipe);
      dispatch({ type: "CREATE", recipe: { ...newRecipe, id: res.id } });
      showToast(t("Recipes.RecipeDuplicated"));
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  async function toggleGroceryListRecipe() {
    const recipeIngredients = ingredients
      .filter(ingredient => ingredient.label && !ingredient.isCategory)
      .map(ingredient => ({
        id: `${recipeId}-${ingredient.id}`,
        label: ingredient.label,
        amount: ingredient.amount,
        unit: ingredient.unit,
        recipeId: recipeId,
      }));

    let newGroceryList;
    if (isAddedToGroceryList) {
      newGroceryList = groceryList.filter(item => item.recipeId !== recipeId);
    } else {
      newGroceryList = [...recipeIngredients, ...groceryList];
    }

    try {
      await callApi("groceries", "put", { groceries: newGroceryList });
      dispatchGroceryList({ type: "UPDATE", groceryList: newGroceryList });
      showToast(isAddedToGroceryList ? t("Recipes.RemoveFromGroceriesSuccess") : t("Recipes.AddToGroceriesSuccess"));
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  async function toggleIsFavorite() {
    const newRecipe = { ...recipe, isFavorite: !isFavorite };
    try {
      await callApi("recipes", "put", newRecipe);
      dispatch({ type: "UPDATE", recipe: newRecipe });
      showToast(isFavorite ? t("Recipes.RemoveFromFavoritesSuccess") : t("Recipes.AddToFavoritesSuccess"));
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  async function toggleNeverCooked() {
    const newRecipe = { ...recipe, neverCooked: !neverCooked };
    try {
      await callApi("recipes", "put", newRecipe);
      dispatch({ type: "UPDATE", recipe: { ...recipe, neverCooked: !neverCooked } });

      showToast(neverCooked ? t("Recipes.MarkCookedSuccess") : t("Recipes.MarkNeverCookedSuccess"));
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  async function deleteRecipe() {
    try {
      await callApi(`recipes/${recipeId}`, "delete");
      if (onClose) onClose();
      dispatch({ type: "DELETE", id: recipeId });
      showToast(t("Recipes.RecipeDeleted"));
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  const actionItems = [
    {
      id: "start-cooking",
      icon: "play_arrow",
      label: t("Recipes.StartCooking"),
      onClick: () => {
        if (onClose) onClose();
        navigate(`/app/cooking-mode/${recipeId}`);
      },
    },
    {
      id: "edit",
      icon: "edit",
      label: t("Common.Edit"),
      onClick: () => {
        if (onClose) onClose();
        navigate(`${locationBase}/${recipeId}/edit`);
      },
    },
    {
      id: "duplicate",
      icon: "content_copy",
      label: t("Common.Duplicate"),
      onClick: duplicateRecipe,
    },
    {
      id: "share",
      icon: "share",
      label: t("Common.Share"),
      onClick: () => {
        if (onClose) onClose();
        navigate(`${locationBase}/${recipeId}/share`);
      },
    },
    {
      variant: "divider",
    },
    {
      id: "calendar",
      icon: "event",
      label: "Add to calendar",
      onClick: () => {
        navigate(`${locationBase}/${recipeId}/add-to-calendar`);
      },
    },
    {
      id: "grocery-list",
      icon: isAddedToGroceryList ? "shopping_cart_off" : "shopping_cart",
      label: isAddedToGroceryList ? t("Recipes.RemoveFromGroceries") : t("Recipes.AddToGroceries"),
      onClick: toggleGroceryListRecipe,
      disabled: !hasIngredients,
    },
    {
      variant: "divider",
    },
    {
      id: "favorite",
      icon: isFavorite ? "heart_broken" : "favorite",
      label: isFavorite ? t("Recipes.RemoveFromFavorites") : t("Recipes.AddToFavorites"),
      onClick: toggleIsFavorite,
    },
    {
      id: "never-cooked",
      icon: neverCooked ? "check" : "close",
      label: neverCooked ? t("Recipes.MarkCooked") : t("Recipes.MarkNeverCooked"),
      onClick: toggleNeverCooked,
    },
    {
      variant: "divider",
    },
    {
      id: "delete",
      icon: "delete",
      label: t("Common.Delete"),
      variant: "destructive",
      onClick: deleteRecipe,
    },
  ];

  return { duplicateRecipe, toggleIsFavorite, toggleNeverCooked, recipeActions: actionItems };
};

useRecipeActions.propTypes = {
  recipeId: T.string,
  onClose: T.func,
};
