import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Button from "commons/components/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "commons/util/useToast";
import { useAuth } from "App/AuthContext";
import Typography from "commons/components/Typography";
import { confirmUserRegistration, registerUser, resendRegistrationCode } from "commons/util/auth";
import { Card, Prompt, ButtonStyled, TextInputStyled, TextLinkStyled, Title } from "./Authentication.styles";
import ErrorBlock from "commons/components/ErrorBlock";
import { isEmailValid } from "commons/util/helpers";
import { CODE_LENGTH, MIN_PASSWORD_LENGTH } from "commons/util/constants";
import TextInput from "commons/components/TextInput";
import callApi from "commons/util/callApi";
import { defaultPreferences, defaultTagGroups } from "./defaults.const";
import Flexbox from "commons/components/Flexbox";
import { Trans, useTranslation } from "react-i18next";

const Email = styled.span`
  color: var(--primary-100);
  word-break: break-word;
`;

const TextButton = styled.button`
  color: var(--primary-100);
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorBlockStyled = styled(ErrorBlock)`
  margin: -8px 0 24px 0;
`;

const VIEWS = {
  REGISTER: "REGISTER",
  CODE: "CODE",
};

function Registration() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [view, setView] = useState(VIEWS[searchParams.get("view")] || VIEWS.REGISTER);
  const [generalError, setGeneralError] = useState(""); // to-do: translations
  const [isProcessing, setIsProcessing] = useState(false);
  const { loginUser } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  async function setDefaultPreferences() {
    await callApi("settings", "post", defaultPreferences); // try-catch when used
  }

  async function setDefaultTagGroups() {
    const defaultTagGroupsWithTranslatedLabels = defaultTagGroups.map(tagGroup => ({
      ...tagGroup,
      label: t(`DefaultTags.${tagGroup.label}`),
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        label: t(`DefaultTags.${tag.label}`),
      })),
    }));
    await callApi("tags", "post", { tagGroups: defaultTagGroupsWithTranslatedLabels }); // try-catch when used
  }

  function validateName() {
    if (!name) {
      setNameError(t("Authentication.EmptyName"));
      return false;
    }
    return true;
  }

  function validateEmail() {
    if (!isEmailValid(email)) {
      setEmailError(t("Authentication.InvalidEmail"));
      return false;
    }
    return true;
  }

  function validatePassword() {
    if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(t("Authentication.InvalidPassword", { length: MIN_PASSWORD_LENGTH }));
      return false;
    }
    return true;
  }

  useEffect(() => {
    setNameError("");
    setEmailError("");
    setPasswordError("");
    setGeneralError("");
  }, [view]);

  useEffect(() => {
    if (nameError) setNameError("");
  }, [name]);

  useEffect(() => {
    if (emailError) setEmailError("");
  }, [email]);

  useEffect(() => {
    if (passwordError) setPasswordError("");
  }, [password]);

  async function handleRegister() {
    setGeneralError("");
    const isValidName = validateName();
    const isValidEmail = validateEmail();
    const isValidPassword = validatePassword();

    if (isValidName && isValidEmail && isValidPassword) {
      setIsProcessing(true);
      try {
        await registerUser(name.trim(), email.trim(), password.trim());
        setView(VIEWS.CODE);
      } catch (e) {
        setGeneralError(e.message);
      } finally {
        setIsProcessing(false);
      }
    }
  }

  // ==== registration confirmatioon ====

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  function validateCode() {
    if (!code) {
      setCodeError(t("Authentication.EmptyCode"));
      return false;
    }
    if (code.length !== CODE_LENGTH) {
      setCodeError(t("Authentication.InvalidCode", { length: CODE_LENGTH }));
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (codeError) setCodeError("");
  }, [code]);

  async function handleConfirmRegistration() {
    setGeneralError("");
    const isValidCode = validateCode();

    if (isValidCode) {
      setIsProcessing(true);
      try {
        await confirmUserRegistration(email, code);
        await loginUser(email, password);
        await setDefaultPreferences();
        await setDefaultTagGroups();
        showToast(t("Authentication.LoginSuccess"));
        navigate("/app", { state: { preferences: defaultPreferences, tagGroups: defaultTagGroups } });
      } catch (e) {
        setGeneralError(e.message);
        setIsProcessing(false);
      }
    }
  }

  async function handleResendCode() {
    setGeneralError("");

    try {
      await resendRegistrationCode(email);
      showToast(t("Authentication.CodeResent"));
    } catch (e) {
      setGeneralError(e.message);
    }
  }

  return (
    <>
      {view === VIEWS.REGISTER && (
        <Card flexDirection="column">
          <Title>{t("Authentication.CreateAnAccount")}</Title>
          <ErrorBlockStyled>{generalError}</ErrorBlockStyled>
          <TextInputStyled
            label={t("Common.Name")}
            value={name}
            onChange={setName}
            onConfirm={handleRegister}
            onBlur={validateName}
            errorMessage={nameError}
            autoFocus
            maxLength={40}
          />
          <TextInputStyled
            type="email"
            label={t("Common.Email")}
            value={email}
            onChange={setEmail}
            onConfirm={handleRegister}
            onBlur={validateEmail}
            errorMessage={emailError}
            maxLength={128}
          />
          <TextInputStyled
            type={isPasswordVisible ? "text" : "password"}
            label={t("Common.Password")}
            value={password}
            onChange={setPassword}
            onConfirm={handleRegister}
            onBlur={validatePassword}
            errorMessage={passwordError}
            leftHint={t("Authentication.MinimumChars", { length: MIN_PASSWORD_LENGTH })}
            rightIcon={isPasswordVisible ? "visibility" : "visibility_off"}
            onRightIconClick={() => setIsPasswordVisible(isVisible => !isVisible)}
            maxLength={128}
          />
          <ButtonStyled size="medium" onClick={handleRegister} loading={isProcessing} fullWidth>
            {t("Authentication.CreateAccount")}
          </ButtonStyled>

          <Prompt>
            {t("Authentication.AlreadyHaveAccount")}
            <TextLinkStyled to="/auth/login">{t("Authentication.SignIn")}</TextLinkStyled>
          </Prompt>
        </Card>
      )}
      {view === VIEWS.CODE && (
        <Card flexDirection="column" gap={24}>
          <Typography variant="h3">{t("Authentication.CheckYourEmail")}</Typography>
          <ErrorBlock>{generalError}</ErrorBlock>
          <Typography color="neutral-120">
            {email ? (
              <Trans i18nKey="Authentication.CodeSentTo" values={{ length: CODE_LENGTH, email }}>
                X-digit code has been sent to
                <Email>{email}</Email>
              </Trans>
            ) : (
              <>{t("Authentication.CodeSent", { length: CODE_LENGTH })}</>
            )}
          </Typography>
          <TextInput
            value={code}
            onChange={setCode}
            onConfirm={handleConfirmRegistration}
            onBlur={validateCode}
            errorMessage={codeError}
            placeholder={t("Authentication.EnterCode")}
            pattern="\d*"
            autoFocus
            maxLength={10}
          />
          <Typography color="neutral-120">
            <Trans i18nKey="Authentication.CodeInfoBlock">
              Didn&apos;t receive the code? Check your spam folder or
              <TextButton onClick={handleResendCode}>resend code</TextButton>.
            </Trans>
          </Typography>
          <Flexbox gap={8} justifyContent="flex-end">
            <Button size="medium" variant="tertiary" onClick={() => setView(VIEWS.REGISTER)}>
              {t("Common.Back")}
            </Button>
            <Button size="medium" onClick={handleConfirmRegistration} loading={isProcessing}>
              {t("Authentication.VerifyEmail")}
            </Button>
          </Flexbox>
        </Card>
      )}
    </>
  );
}

export default Registration;
