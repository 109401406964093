import React, { useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import Flexbox from "commons/components/Flexbox";
import Image from "commons/components/Image";
import manCooking from "commons/images/manCooking.svg";
import womanCooking from "commons/images/womanCooking.svg";
import CurveSVG from "commons/images/curve.svg";
import { features, questions } from "./Home.const";
import Question from "../components/Question";
import Feature from "../components/Feature";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { motion, useScroll, useTransform } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";

const Box = styled.div`
  overflow-x: hidden;
  position: relative;
  background-image: url(${CurveSVG});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 168vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    background-image: none;
  }
`;

const Container = styled.main`
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 32px;
`;

const HeroSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 680px;
  max-width: 100%;
  gap: 16px;
  margin: 96px auto;
`;

const cookingImageStyles = css`
  position: absolute;
  width: 232px;
  top: -187px;

  @media (max-width: ${BREAKPOINTS.large}) {
    width: 200px;
    top: -161px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 120px;
    top: -97px;
  }
`;

const WomanCookingImage = styled.img`
  left: 24px;
  ${cookingImageStyles};

  @media (max-width: ${BREAKPOINTS.small}) {
    left: 0;
  }
`;

const ManCookingImage = styled.img`
  right: 24px;
  ${cookingImageStyles};

  @media (max-width: ${BREAKPOINTS.small}) {
    right: 0;
  }
`;

const DemoImageWrap = styled.div`
  position: relative;
  margin-top: 32px;
  width: 90vw;
  max-width: 1262px;
  padding: 48px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--primary-100);
    border-radius: var(--border-radius-5);
    opacity: 0.1;
    z-index: var(--z-index-below);
  }

  @media (max-width: ${BREAKPOINTS.large}) {
    padding: 40px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 24px;
    width: 100vw;
    &:before {
      border-radius: var(--border-radius-3);
    }
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: 24px 16px;
    &:before {
      border-radius: var(--border-radius-2);
    }
  }
`;

const DemoImageFeature = styled.div`
  position: absolute;
  padding: 16px 24px;
  border-radius: var(--border-radius-5);
  background: var(--blue-190);
  box-shadow: var(--shadow-2);

  &:nth-child(1) {
    top: 20%;
    left: 40px;
  }
  &:nth-child(2) {
    top: 60%;
    left: 40px;
  }
  &:nth-child(3) {
    top: 30%;
    right: 40px;
  }
  &:nth-child(4) {
    top: 70%;
    right: 40px;
  }

  @media (max-width: ${BREAKPOINTS.xlarge}) {
    &:nth-child(1) {
      left: 8px;
    }
    &:nth-child(2) {
      left: 8px;
    }
    &:nth-child(3) {
      right: 8px;
    }
    &:nth-child(4) {
      right: 8px;
    }
  }

  @media (max-width: ${BREAKPOINTS.large}) {
    display: none;
  }
`;

const DemoImage = styled.img`
  width: 800px;
  max-width: 100%;
  height: 600px;
  background: var(--neutral-200);
  box-shadow: var(--shadow-2);
  border-radius: var(--border-radius-4);
  object-fit: contain;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: auto;
    border-radius: var(--border-radius-3);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    border-radius: var(--border-radius-2);
  }
`;

const HighlightsSection = styled.section`
  margin: 96px auto;
  text-align: center;
`;

const TypographyLine = styled(Typography)`
  font-weight: 500;
  margin: 24px 0;

  @media (max-width: ${BREAKPOINTS.medium}) {
    line-height: 40px;
  }
`;

const Highlight = styled.span`
  display: inline-block;
  position: relative;
  margin: 0 8px;
  color: var(--neutral-200);
  font-weight: 700 !important;
  z-index: var(--z-index-0);

  &:before {
    content: "";
    position: absolute;
    left: -8px;
    right: -8px;
    top: 0;
    bottom: -2px;
    border-radius: var(--border-radius-2);
    z-index: var(--z-index-below);
    ${({ color }) => color && `background-color: var(--${color}-100);`}
  }
`;

const FeaturesSection = styled.section`
  position: relative;
  margin: 182px auto;

  @media (max-width: ${BREAKPOINTS.medium}) {
    margin: 96px auto 72px;
  }
`;

const FeatureScreenshot = styled(Image)`
  margin-top: -52px;
  box-shadow: var(--shadow-2);
  border-radius: var(--border-radius-4);
  margin-left: 72px;
  object-position: left;

  @media (max-width: ${BREAKPOINTS.medium}) {
    margin: 0;
    border-radius: var(--border-radius-2);
  }
`;

const ActionSection = styled(Flexbox)`
  position: relative;
  margin: 64px auto 96px;
  padding: 80px;
  background-color: var(--primary-90);
  border-radius: var(--border-radius-4);
  overflow: hidden;
  z-index: var(--z-index-0);
  text-align: center;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 24px;
  }
`;

const ActionShine = styled(motion.div)`
  position: absolute;
  transform: rotate(30deg);
  width: 336px;
  height: 800px;
  top: -176px;
  background-color: var(--primary-100);
  z-index: var(--z-index-below);
`;

const FAQSection = styled(Flexbox)`
  margin: 96px auto 96px;
`;

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

function Home() {
  const { t } = useTranslation();
  const heroRef = useRef(null);
  const actionRef = useRef(null);
  const [activeFeatureId, setActiveFeatureId] = useState(features[0].id);
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);

  const { scrollYProgress } = useScroll({ target: actionRef });
  const parallaxY = useParallax(scrollYProgress, 800);

  const activeFeature = useMemo(() => features.find(f => f.id === activeFeatureId), [activeFeatureId]);

  const [openQuestionIds, setOpenQuestionIds] = useState([]);

  function onQuestionClick(questionId) {
    setOpenQuestionIds(ids => {
      if (ids.includes(questionId)) {
        return ids.filter(id => id !== questionId);
      } else {
        return [...ids, questionId];
      }
    });
  }

  return (
    <>
      <Box>
        <Container>
          <HeroSection ref={heroRef}>
            <Typography variant={isScreenMaxMedium ? "h2" : "h1"} as="h1">
              {t("Home.HeroTitle")}
            </Typography>
            <Typography variant="paragraph" style={{ maxWidth: "440px" }}>
              <Trans i18nKey="Home.HeroParagraph">
                Meet Cook Your Goose: Your elegant recipe manager
                {!isScreenMaxMedium && <br />} for effortless recipe storage, customization, and sharing.
              </Trans>
            </Typography>
            <Button link="auth/register" size="medium">
              {t("Home.HeroButton")}
            </Button>
            <DemoImageWrap>
              <WomanCookingImage src={womanCooking} alt={t("Home.IllustrationWomanCooking")} />
              <ManCookingImage src={manCooking} alt={t("Home.IllustrationManCooking")} />
              <DemoImage src={"./demo.webp"} alt={t("Home.Screenshot")} />
              <div>
                <DemoImageFeature>{t("Home.DemoImageFeature1")}</DemoImageFeature>
                <DemoImageFeature>{t("Home.DemoImageFeature2")}</DemoImageFeature>
                <DemoImageFeature>{t("Home.DemoImageFeature3")}</DemoImageFeature>
                <DemoImageFeature>{t("Home.DemoImageFeature4")}</DemoImageFeature>
              </div>
            </DemoImageWrap>
          </HeroSection>
          <HighlightsSection>
            <TypographyLine variant={isScreenMaxMedium ? "h3" : "h2"}>
              <Trans i18nKey="Home.HighlightPaperCookbooks">
                Ditch your countless <Highlight color="pink">paper cookbooks.</Highlight>
              </Trans>
            </TypographyLine>
            <TypographyLine variant={isScreenMaxMedium ? "h3" : "h2"}>
              <Trans i18nKey="Home.HighlightBlogBookmarks">
                Quit browsing through <Highlight color="blue">blog bookmarks.</Highlight>
              </Trans>
            </TypographyLine>
            <TypographyLine variant={isScreenMaxMedium ? "h3" : "h2"}>
              <Trans i18nKey="Home.HighlightFamilyRecipes">
                Keep track of <Highlight color="green">family recipes.</Highlight>
              </Trans>
            </TypographyLine>
          </HighlightsSection>
          <FeaturesSection>
            <Typography variant="h3" marginBottom={16}>
              {t("Home.FeaturesTitle")}
            </Typography>
            {isScreenMaxMedium ? (
              <Flexbox flexDirection="column" gap={40}>
                {features.map(feature => (
                  <Flexbox flexDirection="column" key={feature.id} gap={8}>
                    <FeatureScreenshot
                      src={feature.imageURL}
                      alt={`${t(`Home.${feature.title}`)} ${t("Home.FeatureScreenshot")}`}
                      width="100%"
                      height="240px"
                    />
                    <Feature icon={feature.icon} title={feature.title} description={feature.description} />
                  </Flexbox>
                ))}
              </Flexbox>
            ) : (
              <Flexbox>
                <Flexbox gap={48} marginTop={20} marginLeft={-16}>
                  <Flexbox flexDirection="column" gap={24}>
                    {features.slice(0, 3).map(feature => (
                      <Feature
                        key={feature.id}
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                        isActive={activeFeatureId === feature.id}
                        onClick={() => setActiveFeatureId(feature.id)}
                      />
                    ))}
                  </Flexbox>
                  <Flexbox flexDirection="column" gap={24}>
                    {features.slice(3, 6).map(feature => (
                      <Feature
                        key={feature.id}
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                        isActive={activeFeatureId === feature.id}
                        onClick={() => setActiveFeatureId(feature.id)}
                      />
                    ))}
                  </Flexbox>
                </Flexbox>
                <FeatureScreenshot
                  src={activeFeature.imageURL}
                  alt={`${t(`Home.${activeFeature.title}`)} ${t("Home.FeatureScreenshot")}`}
                  width="calc((100vw - 1024px) / 2 + 386px)"
                  height="674px"
                />
              </Flexbox>
            )}
          </FeaturesSection>
          <ActionSection forwardedAs="section" flexDirection="column" alignItems="center" ref={actionRef}>
            <ActionShine
              style={{
                x: parallaxY,
                rotate: 30,
              }}
            />
            <Typography variant={isScreenMaxMedium ? "h3" : "h2"} color="neutral-200" marginBottom={12}>
              {t("Home.CtaTitle")}
            </Typography>
            <Typography variant="paragraph" color="neutral-200" marginBottom={32}>
              {t("Home.CtaParagraph")}
            </Typography>
            <Button variant="secondary" size="medium" link="/auth/register">
              {t("Home.CtaButton")}
            </Button>
          </ActionSection>
          <FAQSection forwardedAs="section" flexDirection="column" gap={24}>
            <Typography variant="h3">{t("Home.FaqTitle")}</Typography>
            <Flexbox flexDirection="column" gap={16}>
              {questions.map(question => (
                <Question
                  key={question.id}
                  title={question.title}
                  content={question.content}
                  isOpen={openQuestionIds.includes(question.id)}
                  onClick={() => onQuestionClick(question.id)}
                />
              ))}
            </Flexbox>
          </FAQSection>
        </Container>
      </Box>
    </>
  );
}

export default Home;
