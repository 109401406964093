import GenericErrorPage from "GenericErrorPage";
import React from "react";

export default Component =>
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      // console.error("-- 1 ErrorBoundary");
      return { hasError: true };
    }

    // componentDidCatch(error, errorInfo) {
    componentDidCatch() {
      // You can also log the error to an error reporting service
      // console.error("-- 2 ErrorBoundary", error, errorInfo);
    }

    render() {
      // console.log("hasError", this.state.hasError);
      if (this.state.hasError) {
        return <GenericErrorPage />;
      }

      // return this.props.children;

      return <Component {...this.props} />;
    }
  };
