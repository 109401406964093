import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import T from "prop-types";
import callApi from "commons/util/callApi";

export const GroceryListContext = createContext(null);
export const GroceryListDispatchContext = createContext(null);

export function useGroceryList() {
  return useContext(GroceryListContext);
}

export function useGroceryListDispatch() {
  return useContext(GroceryListDispatchContext);
}

export function GroceryListProvider({ children }) {
  const [groceryList, dispatch] = useReducer(groceryListReducer, initialGroceryList);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchGroceryList() {
      try {
        const res = await callApi("groceries", "get");
        dispatch({ type: "INIT", groceryList: res?.groceries || [] });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchGroceryList();
  }, []);

  const groceryListContextValue = {
    groceryList,
    isLoading,
  };

  return (
    <GroceryListContext.Provider value={groceryListContextValue}>
      <GroceryListDispatchContext.Provider value={dispatch}>{children}</GroceryListDispatchContext.Provider>
    </GroceryListContext.Provider>
  );
}

GroceryListProvider.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};

const initialGroceryList = [];

function groceryListReducer(groceryList, action) {
  switch (action.type) {
    case "INIT": {
      return action.groceryList;
    }
    case "UPDATE": {
      return action.groceryList;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
