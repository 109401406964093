import React from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "commons/components/Logo";
import Flexbox from "commons/components/Flexbox";
import Popover from "commons/components/Popover";
// import TextInput from "commons/components/TextInput";
import ActionList from "commons/components/ActionList";
import Icon from "commons/components/Icon";
import Typography from "commons/components/Typography";
import { useAuth } from "./AuthContext";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useTranslation } from "react-i18next";
import ClampedTextWithTooltip from "commons/components/ClampedTextWithTooltip";

const Box = styled(Flexbox)`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 32px;
  background-color: var(--secondary-190);
  width: 100%;
  border-radius: var(--border-radius-0);
  z-index: var(--z-index-above);

  @media (max-width: ${BREAKPOINTS.medium}) {
    .logo-text {
      display: none;
    }
  }
`;

const Links = styled(Flexbox)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const LinkStyled = styled(Link)`
  color: var(--neutral-120);
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: var(--border-radius-1);

  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: 700;
      color: var(--primary-100);
      background-color: var(--neutral-200);
    `}

  &:hover {
    color: var(--primary-100);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: 4px;
  }
`;

// const SearchInput = styled(TextInput)`
//   width: 160px;

//   span {
//     margin-left: 8px;
//   }
//   input {
//     font-size: 14px;
//     line-height: 16px;
//     padding: 6px;
//     min-height: 0;
//   }
// `;

const UserWrap = styled(Flexbox)`
  max-width: 30%;
`;

const Username = styled(Flexbox)`
  font-weight: 500;
  cursor: pointer;
`;

function Navigation({ className }) {
  const { t } = useTranslation();
  // const [searchPhrase, setSearchPhrase] = useState("");
  const navigate = useNavigate();
  const { user, logoutUser } = useAuth();
  const name = user.name;
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);

  const location = useLocation();

  const userActions = [
    {
      label: t("Navigation.Account"),
      icon: "person",
      link: "/app/settings/account",
    },
    {
      label: t("Navigation.Preferences"),
      icon: "tune",
      link: "/app/settings/preferences",
    },
    {
      label: t("Navigation.Tags"),
      icon: "sell",
      link: "/app/settings/tags",
    },
    {
      variant: "divider",
    },
    {
      label: t("Navigation.WhatsNew"),
      link: "/app",
      disabled: true,
    },
    {
      variant: "divider",
    },
    {
      label: t("Navigation.Feedback"),
      link: "/app/feedback",
    },
    {
      label: t("Navigation.Help"),
      link: "/",
      disabled: true,
    },
    {
      label: t("Authentication.LogOut"),
      onClick: () => {
        logoutUser();
        navigate("/");
      },
    },
  ];

  return (
    <Box className={className} alignItems="center" justifyContent="space-between">
      <Logo link="/app" />
      <Links justifyContent="center" gap={16}>
        <LinkStyled $isActive={location.pathname === "/app" || location.pathname.includes("recipes")} to="recipes">
          {isScreenMaxSmall ? <Icon name="menu_book" size={20} /> : t("Navigation.Recipes")}
        </LinkStyled>
        <LinkStyled $isActive={location.pathname.includes("calendar")} to="calendar">
          {isScreenMaxSmall ? <Icon name="calendar_month" size={20} /> : t("Navigation.Calendar")}
        </LinkStyled>
        <LinkStyled $isActive={location.pathname.includes("groceries")} to="groceries">
          {isScreenMaxSmall ? <Icon name="shopping_cart" size={20} /> : t("Navigation.Groceries")}
        </LinkStyled>
      </Links>
      <UserWrap gap={32} alignItems="center">
        {/* <SearchInput leftIcon="search" placeholder="Search" value={searchPhrase} onChange={setSearchPhrase} /> */}
        <Popover
          trigger={
            isScreenMaxSmall ? (
              <Icon name="person" size={24} color="neutral-140" />
            ) : (
              <Username gap={2}>
                <Typography variant="label">
                  <ClampedTextWithTooltip>{name}</ClampedTextWithTooltip>
                </Typography>
                <Icon name="arrow_drop_down" size={20} />
              </Username>
            )
          }
          content={closePopover => <ActionList closePopover={closePopover} actions={userActions} />}
          isTriggerTabbable
        />
      </UserWrap>
    </Box>
  );
}

Navigation.propTypes = {
  className: T.string,
};

export default Navigation;
