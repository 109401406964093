import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import T from "prop-types";
import callApi from "commons/util/callApi";

export const RecipeListContext = createContext(null);
export const RecipeListDispatchContext = createContext(null);

export function useRecipeList() {
  return useContext(RecipeListContext);
}

export function useRecipeListDispatch() {
  return useContext(RecipeListDispatchContext);
}

export function RecipeListProvider({ children }) {
  const [recipeList, dispatch] = useReducer(recipeListReducer, initialRecipeList);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchRecipes() {
      try {
        const res = await callApi("recipes", "get");
        const sortedRecipeList = res.sort((a, b) => b.dateLastEdited - a.dateLastEdited);
        dispatch({ type: "INIT", recipeList: sortedRecipeList });
      } finally {
        setIsLoading(false);
      }
    }
    fetchRecipes();
  }, []);

  const recipeListContextValue = {
    recipeList,
    isLoading,
  };

  return (
    <RecipeListContext.Provider value={recipeListContextValue}>
      <RecipeListDispatchContext.Provider value={dispatch}>{children}</RecipeListDispatchContext.Provider>
    </RecipeListContext.Provider>
  );
}

RecipeListProvider.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};

const initialRecipeList = [];

function recipeListReducer(recipeList, action) {
  switch (action.type) {
    case "INIT": {
      return action.recipeList;
    }
    case "CREATE": {
      return [
        {
          ...action.recipe,
          dateCreated: Date.now(),
          dateLastEdited: Date.now(),
        },
        ...recipeList,
      ];
    }
    case "UPDATE": {
      return recipeList.map(el => {
        if (el.id === action.recipe.id) {
          return action.recipe;
        } else {
          return el;
        }
      });
    }
    case "DELETE": {
      return recipeList.filter(el => el.id !== action.id);
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
