import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Icon from "commons/components/Icon";

const Box = styled(Flexbox)``;

const FeatureIconWrap = styled.div`
  padding: 9px;
  color: var(--secondary-100);
  background-color: var(--secondary-190);
  border-radius: var(--border-radius-2);
  margin-top: 2px;
`;

const FeatureTitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

function Feature({ className, label, paragraph, icon }) {
  return (
    <Box gap={16} className={className} alignItems="flex-start">
      <FeatureIconWrap>
        <Icon name={icon} size={24} />
      </FeatureIconWrap>
      <Flexbox flexDirection="column" gap={6}>
        <FeatureTitle variant="h4">{label}</FeatureTitle>
        <Typography variant="body">{paragraph}</Typography>
      </Flexbox>
    </Box>
  );
}

Feature.propTypes = {
  className: T.string,
  label: T.string,
  paragraph: T.string,
  icon: T.string,
};

export default Feature;
