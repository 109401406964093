import React, { useEffect, useMemo, useRef, useState } from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Icon from "commons/components/Icon";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import { useTimer } from "react-timer-hook";
import timerSound from "commons/sounds/bell.mp3";
import { useTranslation } from "react-i18next";

const Box = styled(Flexbox)`
  background: var(--neutral-200);
  box-shadow: var(--shadow-2);
  border-radius: var(--border-radius-2);
  overflow: hidden;
  position: fixed;
  right: 16px;
  bottom: ${({ index }) => 16 + 64 + index * (120 + 16)}px;
  z-index: var(--z-index-above);

  ${({ isGoingOff }) =>
    isGoingOff &&
    css`
      box-shadow: 0 0 0 2px var(--primary-100);
      animation: linear 2.6s infinite shake;
    `}

  @keyframes shake {
    0% {
      transform: translate(0, 0);
    }
    4% {
      transform: translate(3px, 0);
    }
    8% {
      transform: translate(-3px, 0);
    }
    12% {
      transform: translate(3px, 0);
    }
    16% {
      transform: translate(-3px, 0);
    }
    20% {
      transform: translate(3px, 0);
    }
    24% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;

const TimerHeader = styled(Flexbox)`
  background-color: var(--neutral-190);
  border-radius: var(--border-radius-0);
`;

const CloseIcon = styled(Icon)`
  /* position: absolute; */
  padding: 8px;
  /* top: 8px; */
  /* right: 8px; */
  border-radius: var(--border-radius-1);
  border-top-right-radius: var(--border-radius-2);
  /* z-index: var(--z-index-0); */
  background-color: var(--neutral-190);

  &:hover {
    background-color: var(--neutral-180);
  }
`;

const Time = styled(Typography)``;

const Label = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
`;

const ProgressBar = styled.div`
  height: 4px;
  background-color: var(--primary-100);
  width: 100%;
  transform: scaleX(0);
  transition-timing-function: linear;
  transform-origin: left;
`;

function Timer({ className, value = 1, label, removeTimer, index, setTimersGoingOff }) {
  const { t } = useTranslation();
  const [isGoingOff, setIsGoingOff] = useState(false);
  const [totalSeconds, setTotalSeconds] = useState(value * 60);

  const time = new Date();
  time.setSeconds(time.getSeconds() + value * 60);
  const expiryTimestamp = time;

  const { seconds, minutes, hours, days, isRunning, pause, resume, restart } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: () => setIsGoingOff(true),
  });

  const currentTimerSecondsLeft = useMemo(
    () => seconds + minutes * 60 + hours * 3600 + days * 86400,
    [seconds, minutes, hours, days]
  );

  const progress = useMemo(() => {
    if (totalSeconds === 0) {
      return 0;
    }
    return ((totalSeconds - currentTimerSecondsLeft) / totalSeconds) * 100;
  }, [isRunning, totalSeconds, currentTimerSecondsLeft]);

  const audioRef = useRef();

  useEffect(() => {
    if (isGoingOff) {
      audioRef.current.play();
      setTimersGoingOff(t => [...t, index]);
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setTimersGoingOff(t => t.filter(i => i !== index));
    }
  }, [isGoingOff]);

  function stopTimer() {
    const newTime = new Date();
    newTime.setSeconds(newTime.getSeconds() + value * 60);
    setTotalSeconds(value * 60);
    restart(newTime, false);
    setIsGoingOff(false);
  }

  function restartTimer() {
    const newTime = new Date();
    newTime.setSeconds(newTime.getSeconds() + value * 60);
    setTotalSeconds(value * 60);
    restart(newTime, true);
    setIsGoingOff(false);
  }

  function addOneMinute() {
    const newTime = new Date();
    const currentTimerSecondsLeft = seconds + minutes * 60 + hours * 3600 + days * 86400;
    newTime.setSeconds(newTime.getSeconds() + currentTimerSecondsLeft + 60);
    setTotalSeconds(currentTimerSecondsLeft + 60);
    restart(newTime, true);
    setIsGoingOff(false);
  }

  return (
    <>
      <audio src={timerSound} ref={audioRef} loop />
      <Box className={className} flexDirection="column" isGoingOff={isGoingOff} index={index}>
        <TimerHeader alignItems="center" justifyContent="space-between" paddingLeft={8}>
          <Label color="neutral-140">{label}</Label>
          <CloseIcon name="close" onClick={removeTimer} />
        </TimerHeader>
        <Flexbox alignItems="center" gap={8} padding={8}>
          <Button
            variant="secondary"
            icon={isGoingOff ? "stop" : isRunning ? "pause" : "play_arrow"}
            onClick={isGoingOff ? stopTimer : isRunning ? pause : resume}
            isIconFilled
          />
          <Time variant="mono">
            {days > 0 && <>{days.toString().padStart(2, "0")}:</>}
            {value >= 60 && <>{hours.toString().padStart(2, "0")}:</>}
            {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
          </Time>
        </Flexbox>
        <Flexbox gap={8} padding="0 8px 4px 8px">
          <Button variant="tertiary" icon="add" onClick={addOneMinute} isIconFilled>
            {t("App.OneMinute")}
          </Button>
          <Button variant="tertiary" icon="replay" onClick={restartTimer} isIconFilled>
            {t("App.Restart")}
          </Button>
        </Flexbox>
        <ProgressBar style={{ transform: `scaleX(${progress}%)` }} />
      </Box>
    </>
  );
}

Timer.propTypes = {
  className: T.string,
  value: T.number,
  label: T.string,
  removeTimer: T.func,
  index: T.number,
  setTimersGoingOff: T.func,
};

export default Timer;
