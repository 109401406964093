import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import T from "prop-types";
import Tag from "commons/components/Tag";
import Icon from "commons/components/Icon";
import Image from "commons/components/Image";
import Popover from "commons/components/Popover";
import Flexbox from "commons/components/Flexbox";
import { Link } from "react-router-dom";
import ActionList from "commons/components/ActionList";
import Typography from "commons/components/Typography";
import { useRecipeActions } from "App/Recipes/useRecipeActions";
import Tooltip from "commons/components/Tooltip";
import ClampedTextWithTooltip from "commons/components/ClampedTextWithTooltip";
import { useTranslation } from "react-i18next";

const LinkBox = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
`;

const CustomButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  width: 24px;
  height: 24px;
  color: var(--neutral-120);
  background: var(--neutral-200);
  box-shadow: var(--shadow-0);
  border-radius: 50%;

  &:hover,
  &:focus-visible {
    color: var(--neutral-100);
    box-shadow: var(--shadow-2);
  }
`;

const RecipeCardImage = styled(Image)`
  background-color: var(--neutral-190);
  border-radius: var(--border-radius-2) var(--border-radius-2) 0 0;
`;

const ActionButtonsWrap = styled(Flexbox)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
`;

const NeverCookedIcon = styled(Icon)`
  padding: 4px;
  background-color: var(--neutral-200);
  border-radius: var(--border-radius-1);
  color: var(--neutral-120);
  box-shadow: var(--shadow-2);
  cursor: default;
`;

const Name = styled(Typography)`
  margin: 8px;
`;

const ExtraTag = styled(Tag)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

function RecipeCard({ className, recipeId, title, imageURL, tags = [], isFavorite, neverCooked, link }) {
  const { t } = useTranslation();
  const { recipeActions, toggleIsFavorite } = useRecipeActions(recipeId);
  const [width, setWidth] = useState(0);
  const boxRef = useRef(null);

  const maxNumberOfTags = useMemo(() => {
    return Math.floor((width + 4) / 24);
  }, [width]);

  useLayoutEffect(() => {
    setWidth(boxRef.current.offsetWidth);
  }, []);

  return (
    <LinkBox className={className} tabIndex="0" to={link}>
      <RecipeCardImage src={imageURL} height="162px" width="100%" alt={title} isRemote />
      <ActionButtonsWrap flexDirection="column" gap={4}>
        <Popover
          trigger={
            <CustomButton>
              <Icon size={17} name="more_vert" />
            </CustomButton>
          }
          content={closePopover => <ActionList actions={recipeActions} closePopover={closePopover} />}
          placement="bottom-end"
        />
        <CustomButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            toggleIsFavorite();
          }}
        >
          <Icon size={17} name="favorite" color={isFavorite ? "red-100" : ""} isFilled={isFavorite} />
        </CustomButton>
      </ActionButtonsWrap>
      {neverCooked && (
        <Tooltip
          label={t("Recipes.NeverCookedBefore")}
          triggerStyles={{ position: "absolute", top: "130px", right: "8px" }}
        >
          <NeverCookedIcon name="local_dining" onClick={e => e.preventDefault()} />
        </Tooltip>
      )}
      <Name variant="h4">
        <ClampedTextWithTooltip maxLines={tags.length ? 2 : 3}>{title}</ClampedTextWithTooltip>
      </Name>
      <Flexbox margin={8} marginTop="auto" flexWrap="wrap" gap={4} ref={boxRef}>
        {tags.slice(0, tags.length > maxNumberOfTags ? maxNumberOfTags - 1 : maxNumberOfTags).map(tag => {
          return <Tag key={tag.id} icon={tag.icon} label={tag.label} color={tag.color} isCompact />;
        })}
        {tags.length > maxNumberOfTags && (
          <ExtraTag label={`+${tags.length - maxNumberOfTags}`} color="grey" isCompact />
        )}
      </Flexbox>
    </LinkBox>
  );
}

RecipeCard.propTypes = {
  className: T.string,
  recipeId: T.string,
  title: T.string,
  imageURL: T.string,
  tags: T.array,
  isFavorite: T.bool,
  neverCooked: T.bool,
  link: T.string,
};

export default RecipeCard;
