import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import ConstructionImage from "commons/images/construction.png";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import { useTranslation } from "react-i18next";

const Image = styled.img`
  width: 400px;
  max-width: 90%;
  margin: 16% auto 32px;
`;

function ComingSoon({ className }) {
  const { t } = useTranslation();
  return (
    <Flexbox className={className} flexDirection="column" alignItems="center">
      <Image src={ConstructionImage} alt={t("App.ComingSoonImageAlt")} />
      <Typography variant="h1" color="primary-100">
        {t("App.ComingSoon")}
      </Typography>
    </Flexbox>
  );
}

ComingSoon.propTypes = {
  className: T.string,
};

export default ComingSoon;
