import React, { useEffect, useState } from "react";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import Setting from "../components/Setting";
import Loader from "commons/components/Loader";
import callApi from "commons/util/callApi";
import { usePreferences, usePreferencesDispatch } from "App/PreferencesContext";
import { useToast } from "commons/util/useToast";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const UNIT_SYSTEMS = {
  metric: "metric",
  imperial: "imperial",
  none: "none",
};

function Preferences() {
  const { t } = useTranslation();
  const { preferences: initPreferences, isLoading } = usePreferences();
  const dispatch = usePreferencesDispatch();
  const { showToast } = useToast();

  const [preferences, setPreferences] = useState();

  useEffect(() => {
    if (!isLoading && typeof preferences === "undefined") {
      setPreferences(initPreferences);
    }
  }, [isLoading, initPreferences]);

  async function updatePreferences(id, value) {
    const newPreferences = { ...preferences, [id]: value };
    setPreferences(newPreferences);

    try {
      await callApi("settings", "put", newPreferences);
      dispatch({ type: "UPDATE", preferences: newPreferences });
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  if (isLoading || !preferences) {
    return <Loader />;
  }

  const configStructure = [
    {
      id: "defaultServings",
      label: t("Settings.DefaultServings"),
      type: "number",
    },
    // {
    //   id: "cooking-view",
    //   label: "Default cooking view",
    //   type: "select",
    //   options: ["steps", "diagram"],
    // },
    // {
    //   id: "round-ingredients",
    //   label: "Round ingredients",
    //   type: "bool",
    // },
    // {
    //   id: "first-week-day",
    //   label: "First day of the week",
    //   type: "select",
    //   options: ["monday", "saturday", "sunday"],
    // },
    {
      id: "unitSystem",
      label: t("Settings.UnitsOfMeasure"),
      type: "select",
      options: [
        { label: t("Settings.UnitMetric"), value: UNIT_SYSTEMS.metric },
        { label: t("Settings.UnitImperial"), value: UNIT_SYSTEMS.imperial },
        { label: t("Settings.UnitDontConvert"), value: UNIT_SYSTEMS.none },
      ],
    },
    // {
    //   id: "player-text-size",
    //   label: "Cooking mode text size",
    //   type: "select",
    //   options: ["small", "medium", "large"],
    // },
    // {
    //   id: "theme",
    //   label: "Theme",
    //   type: "select",
    //   options: ["light", "dark", "system"],
    // },
    // {
    //   id: "meals",
    //   label: "Meals",
    //   type: "custom",
    //   content: <Meals meals={preferences.meals} setPreferences={setPreferences} />,
    // },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Navigation.Preferences")} | Cook Your Goose</title>
      </Helmet>
      <Typography variant="h3">{t("Settings.Preferences")}</Typography>
      <Flexbox flexDirection="column" gap={8} marginTop={24} isBordered>
        {configStructure.map(el => (
          <Setting {...el} key={el.id} value={preferences[el.id]} onChange={value => updatePreferences(el.id, value)} />
        ))}
      </Flexbox>
    </>
  );
}

export default Preferences;
