import { useRecipeList } from "App/RecipeListContext";
import { useCallback, useMemo } from "react";

const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000;

export function useFilteredRecipes(initFilters = {}) {
  const { recipeList, isLoading } = useRecipeList();

  // Function to filter recipes based on the selected filters
  const filterRecipes = useCallback(
    (recipeList, filters = initFilters) => {
      return recipeList.filter(recipe => {
        let passesFilter = true;

        if (filters.search) {
          const searchPhrase = filters.search.toLowerCase();
          const title = recipe.title.toLowerCase();
          const ingredients = recipe.ingredients.map(el => el.label.toLowerCase());
          const tags = recipe.tags?.map(el => el.label.toLowerCase()) || [];

          if (
            !title.includes(searchPhrase) &&
            !ingredients.some(el => el.includes(searchPhrase)) &&
            !tags.some(el => el.includes(searchPhrase))
          ) {
            passesFilter = false;
          }
        }

        if (filters.recent) {
          if (
            new Date() - new Date(recipe.dateCreated) > SEVEN_DAYS_MS &&
            new Date() - new Date(recipe.dateLastEdited) > SEVEN_DAYS_MS
          ) {
            passesFilter = false;
          }
        }

        if (filters.favorite) {
          if (!recipe.isFavorite) {
            passesFilter = false;
          }
        }

        if (filters.neverCooked) {
          if (!recipe.neverCooked) {
            passesFilter = false;
          }
        }

        if (filters.tags?.length) {
          const intersection = recipe.tagIds.filter(id => filters.tags.includes(id));
          if (!intersection.length) {
            passesFilter = false;
          }
        }

        if (filters.dateCreated?.length) {
          const isRange = filters.dateCreated.length === 2;
          const dateCreated = new Date(recipe.dateCreated);
          const rangeStart = new Date(filters.dateCreated[0]);
          const rangeEnd = new Date(filters.dateCreated[1]);

          if (isRange) {
            if (dateCreated < rangeStart || dateCreated > rangeEnd) {
              passesFilter = false;
            }
          } else {
            if (dateCreated < rangeStart) {
              passesFilter = false;
            }
          }
        }

        if (filters.dateLastEdited?.length) {
          const isRange = filters.dateLastEdited.length === 2;
          const dateLastEdited = new Date(recipe.dateLastEdited);
          const rangeStart = new Date(filters.dateLastEdited[0]);
          const rangeEnd = new Date(filters.dateLastEdited[1]);

          if (isRange) {
            if (dateLastEdited < rangeStart || dateLastEdited > rangeEnd) {
              passesFilter = false;
            }
          } else {
            if (dateLastEdited < rangeStart) {
              passesFilter = false;
            }
          }
        }

        if (filters.activeTimeMin) {
          if (recipe.activeTime < filters.activeTimeMin) {
            passesFilter = false;
          }
        }
        if (filters.activeTimeMax) {
          if (recipe.activeTime > filters.activeTimeMax) {
            passesFilter = false;
          }
        }

        if (filters.totalTimeMin) {
          if (recipe.totalTime < filters.totalTimeMin) {
            passesFilter = false;
          }
        }
        if (filters.totalTimeMax) {
          if (recipe.totalTime > filters.totalTimeMax) {
            passesFilter = false;
          }
        }

        return passesFilter;
      });
    },
    [initFilters]
  );

  const filteredRecipeList = useMemo(() => filterRecipes(recipeList, initFilters), [initFilters, recipeList]);
  const filteredRecipeListLength = filteredRecipeList.length;

  const recipeNumber = useMemo(
    () => filterRecipes(recipeList, { ...initFilters, recent: false, favorite: false, neverCooked: false }).length,
    [initFilters, recipeList]
  );

  const recentRecipeList = useMemo(
    () => filterRecipes(recipeList, { ...initFilters, recent: true, favorite: false, neverCooked: false }),
    [initFilters, recipeList]
  );
  const favoriteRecipeList = useMemo(
    () => filterRecipes(recipeList, { ...initFilters, recent: false, favorite: true, neverCooked: false }),
    [initFilters, recipeList]
  );
  const neverCookedRecipeList = useMemo(
    () => filterRecipes(recipeList, { ...initFilters, recent: false, favorite: false, neverCooked: true }),
    [initFilters, recipeList]
  );

  return {
    filteredRecipeList,
    filteredRecipeListLength,
    recentRecipeList,
    favoriteRecipeList,
    neverCookedRecipeList,
    recipeNumber,
    isLoading,
  };
}
