import { BREAKPOINTS } from "commons/util/breakpoints";
import Flexbox from "commons/components/Flexbox";
import TextInput from "commons/components/TextInput";
import styled from "styled-components/macro";
import TextLink from "commons/components/TextLink";
import Button from "commons/components/Button";

export const Card = styled(Flexbox)`
  position: relative;
  width: 440px;
  max-width: calc(100% - 64px);
  margin: auto;
  padding: 40px 48px 48px;
  background: var(--neutral-200);
  border-radius: var(--border-radius-3);
  box-shadow: var(--shadow-2);

  @media (max-width: ${BREAKPOINTS.small}) {
    justify-content: center;
    padding: 32px;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: var(--border-radius-0);
    overflow: auto;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 32px;
  color: var(--secondary-100);
  text-align: center;
`;

export const Prompt = styled.p`
  margin-top: 16px;
  text-align: center;
  font-size: 13px;
  line-height: 18px;
  color: var(--neutral-140);
`;

export const TextLinkStyled = styled(TextLink)`
  margin-left: 4px;
`;

export const TextInputStyled = styled(TextInput)`
  margin-bottom: 24px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 16px;
`;
