import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import ActionList from "commons/components/ActionList";
import Popover from "commons/components/Popover";
import TextInput from "commons/components/TextInput";
import Button from "commons/components/Button";
import Tabs from "commons/components/Tabs";
import { BREAKPOINTS } from "commons/util/breakpoints";
import Flexbox from "commons/components/Flexbox";
import { useMediaQuery } from "commons/util/useMediaQuery";
import FilterPopoverContent from "../components/FilterPopoverContent";
import { useFilteredRecipes } from "commons/util/useFilteredRecipes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecipeList } from "App/RecipeListContext";
import { useLocationBase } from "commons/util/useLocationBase";
import { PLANS, RECIPE_LIMIT } from "commons/util/constants";
import { useAuth } from "App/AuthContext";

const TabsWrap = styled.div`
  @media (max-width: ${BREAKPOINTS.medium}) {
    width: calc(100% + 80px);
    overflow-x: auto;
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const FilterButtonWrap = styled.div`
  position: relative;
`;

const ActiveBadge = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary-140);
`;

function Toolbar({
  filters,
  hasActiveFilters,
  setFilters,
  activeTabId,
  setActiveTabId,
  activeViewTabId,
  setActiveViewTabId,
  searchString,
  setSearchString,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { recipeList } = useRecipeList();
  const locationBase = useLocationBase();
  const { user } = useAuth();
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);

  const { recipeNumber, recentRecipeList, favoriteRecipeList, neverCookedRecipeList } = useFilteredRecipes(filters);

  return (
    <>
      <Flexbox gap={12} alignItems="flex-start">
        <Popover
          trigger={
            <FilterButtonWrap>
              <Button variant="tertiary" size="medium" icon="filter_list">
                {isScreenMaxMedium ? "" : t("Recipes.Filter")}
              </Button>
              {hasActiveFilters && <ActiveBadge />}
            </FilterButtonWrap>
          }
          content={closePopover => (
            <FilterPopoverContent closePopover={closePopover} filters={filters} setFilters={setFilters} />
          )}
          placement="bottom-start"
        />
        <Flexbox flexGrow={1}>
          {/* <Popover
            trigger={ */}
          <TextInput
            placeholder={isScreenMaxSmall ? t("Common.Search") : t("Recipes.SearchFor")}
            leftIcon="search"
            value={searchString}
            onChange={setSearchString}
            rightIcon={searchString ? "close" : ""}
            onRightIconClick={() => setSearchString("")}
            maxLength={200}
          />
          {/* }
            content={<SearchPopoverContent searchString={searchString} setSearchString={setSearchString} />}
            placement="auto-start"
            isFullWidth
            triggerStyles={{ width: "100%" }}
          /> */}
        </Flexbox>
        <Popover
          trigger={
            <Button
              icon="add"
              size="medium"
              onClick={e => {
                if (recipeList.length >= RECIPE_LIMIT && user.accountType === PLANS.free) {
                  e.stopPropagation();
                  navigate(`${locationBase}/upgrade`);
                }
              }}
            >
              {isScreenMaxMedium ? "" : t("Recipes.AddRecipe")}
            </Button>
          }
          content={closePopover => (
            <ActionList
              actions={[
                { label: t("Recipes.StartFromScratch"), icon: "add", link: "/app/recipes/new" },
                {
                  label: t("Recipes.ImportFromWeb"),
                  icon: "language",
                  link: "/app/recipes/new-web",
                },
              ]}
              closePopover={closePopover}
            />
          )}
        />
      </Flexbox>
      <Flexbox justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={16}>
        <TabsWrap>
          <Tabs
            activeTabId={activeTabId}
            setActiveTabId={setActiveTabId}
            tabs={[
              {
                id: "all",
                label: t("Recipes.AllRecipes"),
                badge: String(recipeNumber), // I used String() here to also be able to show "0"
              },
              {
                id: "recent",
                label: t("Recipes.Recent"),
                badge: String(recentRecipeList.length),
              },
              {
                id: "favorite",
                label: t("Recipes.Favorites"),
                badge: String(favoriteRecipeList.length),
              },
              {
                id: "neverCooked",
                label: t("Recipes.NeverCooked"),
                badge: String(neverCookedRecipeList.length),
              },
            ]}
          />
        </TabsWrap>
        <Tabs
          activeTabId={activeViewTabId}
          setActiveTabId={setActiveViewTabId}
          tabs={[
            {
              id: "grid",
              label: t("Recipes.GridView"),
              icon: "grid_view",
            },
            {
              id: "list",
              label: t("Recipes.ListView"),
              icon: "format_list_bulleted",
            },
          ]}
        />
      </Flexbox>
    </>
  );
}

Toolbar.propTypes = {
  filters: T.object,
  hasActiveFilters: T.bool,
  setFilters: T.func,
  activeTabId: T.string,
  setActiveTabId: T.func,
  activeViewTabId: T.string,
  setActiveViewTabId: T.func,
  searchString: T.string,
  setSearchString: T.func,
};

export default Toolbar;
