import { UNIT_SYSTEMS } from "App/Settings/Preferences/Preferences";

export const defaultPreferences = {
  unitSystem: UNIT_SYSTEMS.metric,
  defaultServings: 2,
};

export const defaultTagGroups = [
  {
    id: 0,
    label: "Meal",
    tags: [
      {
        icon: "morning",
        id: 1,
        label: "Breakfast",
        color: "yellow",
      },
      {
        icon: "sunny",
        id: 2,
        label: "Lunch",
        color: "green",
      },
      {
        icon: "sleep",
        id: 3,
        label: "Dinner",
        color: "blue",
      },
      {
        icon: "lollipop-1",
        id: 4,
        label: "Dessert",
        color: "pink",
      },
      {
        icon: "snack",
        id: 5,
        label: "Snack",
        color: "purple",
      },
      {
        icon: "",
        id: 6,
        label: "Appetizer",
        color: "grey",
      },
      {
        id: 7,
        label: "SideDish",
        color: "grey",
      },
      {
        id: 8,
        label: "MainCourse",
        color: "grey",
      },
    ],
  },
  {
    id: 1,
    label: "Cuisine",
    tags: [
      {
        id: 9,
        label: "Polish",
        color: "turquoise",
      },
      {
        id: 10,
        label: "Slovenian",
        color: "turquoise",
      },
      {
        id: 11,
        label: "Italian",
        color: "turquoise",
      },
      {
        id: 12,
        label: "French",
        color: "turquoise",
      },
      {
        id: 13,
        label: "Greek",
        color: "turquoise",
      },
      {
        id: 14,
        label: "Indian",
        color: "turquoise",
      },
      {
        id: 15,
        label: "Chinese",
        color: "turquoise",
      },
      {
        id: 16,
        label: "Japanese",
        color: "turquoise",
      },
      {
        id: 17,
        label: "Vietnamese",
        color: "turquoise",
      },
      {
        id: 18,
        label: "Thai",
        color: "turquoise",
      },
      {
        id: 19,
        label: "Ethiopian",
        color: "turquoise",
      },
      {
        id: 20,
        label: "Nigerian",
        color: "turquoise",
      },
      {
        id: 21,
        label: "Moroccan",
        color: "turquoise",
      },
      {
        id: 22,
        label: "American",
        color: "turquoise",
      },
      {
        id: 23,
        label: "Mexican",
        color: "turquoise",
      },
    ],
  },
  {
    id: 2,
    label: "Diet",
    tags: [
      {
        icon: "mint",
        id: 24,
        label: "Vegetarian",
        color: "green",
      },
      {
        icon: "artichoke",
        id: 25,
        label: "Vegan",
        color: "green",
      },
      {
        icon: "avocado",
        id: 26,
        label: "Keto",
        color: "blue",
      },
      {
        icon: "chicken-leg",
        id: 27,
        label: "Paleo",
        color: "blue",
      },
      {
        icon: "gluten-free",
        id: 28,
        label: "GlutenFree",
        color: "pink",
      },
      {
        icon: "no-sugar",
        id: 29,
        label: "SugarFree",
        color: "pink",
      },
      {
        icon: "speedometer-1",
        id: 30,
        label: "LowCalorie",
        color: "lime",
      },
      {
        icon: "speedometer",
        id: 31,
        label: "HighCalorie",
        color: "orange",
      },
      {
        icon: "healthcare",
        id: 32,
        label: "Healthy",
        color: "lime",
      },
      {
        icon: "food",
        id: 33,
        label: "FastFood",
        color: "orange",
      },
    ],
  },
  {
    id: 3,
    label: "Allergens",
    tags: [
      {
        icon: "nut",
        id: 34,
        label: "Nuts",
        color: "red",
      },
      {
        icon: "fish-1",
        id: 35,
        label: "Fish",
        color: "red",
      },
      {
        icon: "milk",
        id: 36,
        label: "Milk",
        color: "red",
      },
      {
        icon: "egg",
        id: 37,
        label: "Eggs",
        color: "red",
      },
      {
        icon: "beans",
        id: 38,
        label: "Soybeans",
        color: "red",
      },
      {
        icon: "pearl",
        id: 39,
        label: "Shellfish",
        color: "red",
      },
      {
        icon: "cereals",
        id: 40,
        label: "Wheat",
        color: "red",
      },
    ],
  },
  {
    id: 4,
    label: "Occasion",
    tags: [
      {
        icon: "confetti",
        id: 41,
        label: "Party",
        color: "violet",
      },
      {
        icon: "bbq",
        id: 42,
        label: "BBQ",
        color: "violet",
      },
      {
        icon: "hearts",
        id: 43,
        label: "ValentinesDay",
        color: "violet",
      },
      {
        icon: "pumpkin",
        id: 44,
        label: "Halloween",
        color: "violet",
      },
      {
        icon: "easter-egg",
        id: 45,
        label: "SpringHolidays",
        color: "violet",
      },
      {
        icon: "christmas-tree",
        id: 46,
        label: "WinterHolidays",
        color: "violet",
      },
      {
        icon: "clapperboard",
        id: 47,
        label: "MovieNight",
        color: "violet",
      },
      {
        icon: "birthday-cake",
        id: 48,
        label: "Birthday",
        color: "violet",
      },
    ],
  },
  {
    id: 5,
    label: "FoodType",
    tags: [
      {
        icon: "baby-boy",
        id: 49,
        label: "ForKids",
        color: "purple",
      },
      {
        icon: "canned-food",
        id: 50,
        label: "Canning",
        color: "purple",
      },
      {
        icon: "defrost",
        id: 51,
        label: "Freezing",
        color: "purple",
      },
      {
        icon: "soup",
        id: 52,
        label: "Soup",
        color: "purple",
      },
      {
        icon: "loaf",
        id: 53,
        label: "Bread",
        color: "purple",
      },
      {
        icon: "salad",
        id: 54,
        label: "Salad",
        color: "purple",
      },
      {
        icon: "noodle",
        id: 55,
        label: "Pasta",
        color: "purple",
      },
      {
        icon: "juice",
        id: 56,
        label: "Beverage",
        color: "purple",
      },
      {
        icon: "cocktail",
        id: 57,
        label: "Cocktail",
        color: "purple",
      },
      {
        icon: "sandwich",
        id: 58,
        label: "Sandwich",
        color: "purple",
      },
      {
        icon: "sauce",
        id: 59,
        label: "SauceCondiment",
        color: "purple",
      },
      {
        icon: "bone",
        id: 60,
        label: "PetFood",
        color: "purple",
      },
    ],
  },
  {
    id: 6,
    label: "Price",
    tags: [
      {
        icon: "coins",
        id: 61,
        label: "Cheap",
        color: "green",
      },
      {
        icon: "money-bag",
        id: 62,
        label: "Expensive",
        color: "red",
      },
    ],
  },
  {
    id: 7,
    label: "SkillLevel",
    tags: [
      {
        icon: "speedometer-1",
        id: 63,
        label: "Easy",
        color: "green",
      },
      {
        icon: "speedometer",
        id: 64,
        label: "Difficult",
        color: "red",
      },
    ],
  },
];
