export const questions = [
  {
    id: 0,
    title: "Pricing.Question1",
    content: "Pricing.Answer1",
  },
  {
    id: 1,
    title: "Pricing.Question2",
    content: "Pricing.Answer2",
  },
  {
    id: 2,
    title: "Pricing.Question3",
    content: "Pricing.Answer3",
  },
  {
    id: 3,
    title: "Pricing.Question4",
    content: "Pricing.Answer4",
  },
  {
    id: 4,
    title: "Pricing.Question5",
    content: "Pricing.Answer5",
  },
];
