import React from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";
import Logo from "commons/components/Logo";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import { useAuth } from "App/AuthContext";
import Loader from "commons/components/Loader";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

const Box = styled.div``;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  left: 0;
  top: 60px;
  background: linear-gradient(180deg, var(--secondary-190) 0%, var(--neutral-200) 100%);
  z-index: var(--z-index-below);
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: var(--z-index-above);
`;

const Navigation = styled(Flexbox)`
  height: 60px;
  padding: 0 32px;
  background-color: var(--neutral-200);
  width: 100%;
  border-radius: var(--border-radius-0);
`;

const LogoStyled = styled(Logo)`
  margin-right: auto;
`;

// const Links = styled(Flexbox)`
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%, 0);
// `;

// const StyledLink = styled(Link)`
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 16px;
//   color: var(--neutral-120);
//   padding: 4px 8px;

//   ${({ $isActive }) =>
//     $isActive &&
//     css`
//       color: var(--primary-100);
//       font-weight: 700;
//     `};
// `;

function Website() {
  // const location = useLocation();
  const { user, isLoading } = useAuth();
  const { t } = useTranslation();

  if (isLoading) return <Loader containerHeight="100vh" />;

  return (
    <Box>
      <Helmet>
        <title>Cook Your Goose</title>
      </Helmet>
      <Header>
        <Navigation alignItems="center">
          <LogoStyled link="/" />
          {/* <Links gap={8}>
            <StyledLink to="/features" $isActive={location.pathname === "/features"}>
              Features
            </StyledLink>
            <StyledLink to="/pricing" $isActive={location.pathname === "/pricing"}>
              Pricing
            </StyledLink>
            <StyledLink to="/contact" $isActive={location.pathname === "/contact"}>
              Contact
            </StyledLink>
          </Links> */}

          {user ? (
            <Button link="/app">{t("Home.GoToTheApp")}</Button>
          ) : (
            <Flexbox gap={12}>
              <Button variant="secondary" link="/auth/login">
                {t("Authentication.SignIn")}
              </Button>
              <Button link="/auth/register">{t("Authentication.Register")}</Button>
            </Flexbox>
          )}
        </Navigation>
      </Header>
      <Background />
      <Outlet />
      <Footer />
    </Box>
  );
}

export default Website;
