import React, { useState } from "react";
import T from "prop-types";
import Flexbox from "commons/components/Flexbox";
import styled, { css } from "styled-components/macro";
import NumberInput from "commons/components/NumberInput";
import { useUpdateEffect } from "commons/util/useUpdateEffect";

const Box = styled.div`
  width: 100%;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: var(--neutral-120);
`;

const Colon = styled.div`
  padding: 0 4px;
  line-height: 0;
`;

const NumberInputStyled = styled(NumberInput)`
  input {
    width: 100%;
  }
`;

function DurationInput({ className, value, onChange, label, withSeconds = false, ...inputProps }) {
  const [hours, setHours] = useState(Math.floor(value / 60));
  const [minutes, setMinutes] = useState(Math.floor(value % 60));
  const [seconds, setSeconds] = useState(Math.floor((value % 1) * 60));

  useUpdateEffect(() => {
    onChange(+seconds / 60 + +minutes + +hours * 60);
  }, [seconds, minutes, hours]);

  return (
    <Box className={className}>
      {!!label && <Label>{label}</Label>}
      <Flexbox alignItems="center">
        <NumberInputStyled
          type="number"
          value={hours}
          onChange={value => setHours(value)}
          min={0}
          max={100}
          step={1}
          onBlur={() => {
            if (hours === "") setHours(0);
          }}
          unit="h"
          {...inputProps}
        />
        <Colon>:</Colon>
        <NumberInputStyled
          type="number"
          value={minutes}
          onChange={value => setMinutes(value)}
          min={0}
          max={55}
          step={5}
          onBlur={() => {
            if (minutes === "") setMinutes(0);
          }}
          unit="min"
          {...inputProps}
        />
        {withSeconds && (
          <>
            <Colon>:</Colon>
            <NumberInputStyled
              type="number"
              value={seconds}
              onChange={value => setSeconds(value)}
              min={0}
              max={55}
              step={5}
              onBlur={() => {
                if (seconds === "") setSeconds(0);
              }}
              unit="s"
              {...inputProps}
            />
          </>
        )}
      </Flexbox>
    </Box>
  );
}

DurationInput.propTypes = {
  className: T.string,
  value: T.number.isRequired,
  onChange: T.func,
  label: T.string,
  withSeconds: T.bool,
};

export default DurationInput;
