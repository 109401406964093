import React from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";
// import TextInput from "commons/components/TextInput";
import ErrorBoundary from "./ErrorBoundary";
import { RecipeListProvider } from "./RecipeListContext";
import Navigation from "./Navigation";
import RouteGuard from "./RouteGuard";
import { TagGroupsProvider } from "./TagGroupsContext";
import { PreferencesProvider } from "./PreferencesContext";
import { GroceryListProvider } from "./GroceryListContext";
import { MealPlanProvider } from "./MealPlanContext";
import { OfflineNotification } from "commons/components/OfflineNotification/OfflineNotification";
import { Helmet } from "react-helmet";

const Box = styled.div`
  height: 100%; // needed for children that have height: 100% and position: relative
  /* min-height: 100vh; */
  /* overflow: hidden; */
`;

function App() {
  return (
    <RouteGuard>
      <RecipeListProvider>
        <TagGroupsProvider>
          <PreferencesProvider>
            <GroceryListProvider>
              <MealPlanProvider>
                <Helmet>
                  <title>Cook Your Goose</title>
                </Helmet>
                <Box>
                  <Navigation />
                  <Outlet />
                  <OfflineNotification />
                </Box>
              </MealPlanProvider>
            </GroceryListProvider>
          </PreferencesProvider>
        </TagGroupsProvider>
      </RecipeListProvider>
    </RouteGuard>
  );
}

export default ErrorBoundary(App);
