import React from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";
import Logo from "commons/components/Logo";
import { BREAKPOINTS } from "commons/util/breakpoints";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, var(--secondary-190) 0%, var(--neutral-200) 100%);
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 18px;
  left: 32px;
  z-index: var(--z-index-above);

  @media (max-width: ${BREAKPOINTS.small}) {
    position: static;
    padding: 16px 0;
  }
`;

function Authentication() {
  return (
    <Box>
      <StyledLogo link="/" />
      <Outlet />
    </Box>
  );
}

export default Authentication;
