import React, { useMemo } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Modal from "commons/components/Modal";
import { useNavigate } from "react-router-dom";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import Flexbox from "commons/components/Flexbox";
import { useLocationBase } from "commons/util/useLocationBase";
import { Trans, useTranslation } from "react-i18next";
import Divider from "commons/components/Divider";
import { useRecipeList } from "App/RecipeListContext";
import Image from "commons/components/Image";
import { RECIPE_LIMIT } from "commons/util/constants";

const StyledModal = styled(Modal)`
  width: 560px;
  height: auto;
`;

const RecipeImagesWrap = styled.div`
  height: 240px;
  overflow: hidden;
  position: relative;
  border-radius: var(--border-radius-2);
`;

const RecipeImages = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  position: absolute;
  top: 100%;
  left: 22%;
  transform: rotate(22deg) translateY(-50%) translateX(-50%);
`;

const StyledImage = styled(Image)`
  border-radius: var(--border-radius-2);
`;

function RecipeLimitModal({ className }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationBase = useLocationBase();
  const { recipeList, isLoading } = useRecipeList();

  const recipeImageUrls = useMemo(() => recipeList.map(recipe => recipe.imageURL).filter(Boolean), [isLoading]);

  return (
    <StyledModal
      className={className}
      onClose={() => navigate(locationBase)}
      content={closeModal => (
        <>
          <Typography variant="h3" margin="16px 24px">
            {t("Upgrade.Title")}
          </Typography>
          <Divider />
          <Flexbox flexDirection="column" padding={24} gap={16}>
            <RecipeImagesWrap>
              <RecipeImages>
                {recipeImageUrls.slice(0, 20).map(imageUrl => (
                  <StyledImage
                    key={imageUrl}
                    width="118px"
                    height="118px"
                    src={imageUrl}
                    alt={t("Recipes.RecipeImage")}
                    isRemote
                  />
                ))}
              </RecipeImages>
            </RecipeImagesWrap>
            <Typography variant="paragraph">{t("Upgrade.Paragraph1", { limit: RECIPE_LIMIT })}</Typography>
            <Typography variant="paragraph">
              <Trans i18nKey="Upgrade.Paragraph2">
                Ready for more?
                <br />
                Upgrade to <b>Premium</b> now and keep adding to your collection.
                <br />
                Enjoy unlimited recipes and extra features with your upgrade.
              </Trans>
            </Typography>
          </Flexbox>
          <Divider />
          <Flexbox justifyContent="flex-end" padding="16px 24px" gap={8}>
            <Button onClick={closeModal} variant="tertiary">
              {t("Upgrade.NotNow")}
            </Button>
            <Button link="/pricing">{t("Upgrade.ExplorePremiumFeatures")}</Button>
          </Flexbox>
        </>
      )}
    />
  );
}

RecipeLimitModal.propTypes = {
  className: T.string,
};

export default RecipeLimitModal;
