import React, { useState } from "react";
import styled from "styled-components/macro";
import Button from "commons/components/Button";
import Modal from "commons/components/Modal";
import Flexbox from "commons/components/Flexbox";
import { useAuth } from "App/AuthContext";
import Typography from "commons/components/Typography";
import { Trans, useTranslation } from "react-i18next";

const DeleteAccountWrap = styled(Flexbox)`
  margin-bottom: 16px;
`;

const ModalStyled = styled(Modal)`
  width: 640px;
  height: auto;
`;

const LinkText = styled.a`
  color: var(--primary-100);

  &:hover {
    text-decoration: underline;
    color: var(--primary-90);
  }
`;

function DeleteAccount() {
  const { t } = useTranslation();
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const { deleteUser, logoutUser } = useAuth();

  return (
    <>
      <DeleteAccountWrap padding={16} justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={10}>
        <Typography variant="h4">{t("Settings.DeleteAccount")}</Typography>
        <Flexbox gap={8} flexWrap="wrap">
          <Button variant="tertiary" onClick={() => setShowDeleteAccountModal(true)}>
            {t("Settings.DeleteAccount")}
          </Button>
          <Button variant="secondary" onClick={logoutUser}>
            {t("Authentication.LogOut")}
          </Button>
        </Flexbox>
      </DeleteAccountWrap>

      {showDeleteAccountModal && (
        <ModalStyled
          onClose={() => setShowDeleteAccountModal(false)}
          content={closeModal => (
            <Flexbox flexDirection="column" gap={16} padding={32}>
              <Typography variant="h3">{t("Settings.WeAreSorry")}</Typography>
              <Typography variant="paragraph">
                <Trans i18nKey="Settings.DeleteAccountInfoBlock">
                  If you no longer love us you can delete your account. Be warned, there is no turning back and all your
                  recipes will be permanently deleted. There will be no way to restore your account.
                  <br />
                  <br />
                  If you&apos;d like to share feedback with us, you can{" "}
                  <LinkText href="/contact" target="_blank">
                    do that here.
                  </LinkText>
                </Trans>
              </Typography>
              <Flexbox gap={8} justifyContent="flex-end">
                <Button variant="tertiary" onClick={closeModal}>
                  {t("Common.Cancel")}
                </Button>
                <Button onClick={deleteUser}>{t("Settings.DeleteAccount")}</Button>
              </Flexbox>
            </Flexbox>
          )}
        />
      )}
    </>
  );
}

export default DeleteAccount;
