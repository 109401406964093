import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Typography from "commons/components/Typography";
import TextInput from "commons/components/TextInput";
import Button from "commons/components/Button";
import Flexbox from "commons/components/Flexbox";
import Checkbox from "commons/components/Checkbox";
import SelectInput from "commons/components/SelectInput";
import { useToast } from "commons/util/useToast";
import { useAuth } from "App/AuthContext";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { isEmailValid } from "commons/util/helpers";
import DragAndDropInput from "commons/components/DragAndDropInput";
import { useTranslation } from "react-i18next";

const Box = styled.div`
  width: 900px;
  max-width: 100%;
  text-align: center;
  margin: 100px auto;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 32px;
    padding-right: 32px;
    margin: 40px auto;
  }
`;

const Form = styled(Flexbox)`
  width: 400px;
  max-width: 100%;
  text-align: left;
  margin: 48px auto;
`;

function Contact({ className }) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [topic, setTopic] = useState();
  const [topicError, setTopicError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const [isContactable, setIsContactable] = useState(false);

  const [name, setName] = useState(user?.name || "");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState(user?.email || "");
  const [emailError, setEmailError] = useState("");

  const [isSending, setIsSending] = useState(false);
  const { showToast } = useToast();

  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);

  function validateTopic() {
    if (!topic) {
      setTopicError(t("Contact.EmptyTopic"));
      return false;
    }
    return true;
  }

  function validateMessage() {
    if (!message) {
      setMessageError(t("Contact.EmptyMessage"));
      return false;
    }
    return true;
  }

  function validateName() {
    if (isContactable && !name) {
      setNameError(t("Authentication.EmptyName"));
      return false;
    }
    return true;
  }

  function validateEmail() {
    if (isContactable && !isEmailValid(email)) {
      setEmailError(t("Authentication.InvalidEmail"));
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (topicError) setTopicError("");
  }, [topic]);

  useEffect(() => {
    if (messageError) setMessageError("");
  }, [message]);

  useEffect(() => {
    if (nameError) setNameError("");
  }, [name]);

  useEffect(() => {
    if (emailError) setEmailError("");
  }, [email]);

  function sendMessage() {
    const isValidTopic = validateTopic();
    const isValidMessage = validateMessage();
    const isValidName = validateName();
    const isValidEmail = validateEmail();

    if (isValidTopic && isValidMessage && isValidName && isValidEmail) {
      setIsSending(true);
      // actual API call goes here
      //
      // and this should happen after awaiting
      setTimeout(() => {
        setIsSending(false);
        setTopic();
        setMessage("");
        showToast(t("Contact.MessageSent"));
      }, 1000);
    }
  }

  return (
    <Box className={className}>
      <Typography variant={isScreenMaxMedium ? "h3" : "h1"} as="h1">
        {t("Contact.Title")}
      </Typography>
      <Typography variant="paragraph" marginTop={16}>
        {t("Contact.Paragraph")}
      </Typography>
      <Form flexDirection="column" gap={24}>
        <SelectInput
          value={topic}
          onChange={setTopic}
          label={t("Contact.Topic")}
          placeholder={t("Contact.TopicPlaceholder")}
          options={[
            {
              id: "question",
              label: t("Contact.TopicQuestion"),
            },
            {
              id: "comment",
              label: t("Contact.TopicComment"),
            },
            {
              id: "compliment",
              label: t("Contact.TopicCompliment"),
            },
            {
              id: "bug",
              label: t("Contact.TopicBug"),
            },
            {
              id: "improvement",
              label: t("Contact.TopicImprovement"),
            },
            {
              id: "other",
              label: t("Contact.TopicOther"),
            },
          ]}
          errorMessage={topicError}
        />
        <TextInput
          value={message}
          onChange={setMessage}
          label={t("Contact.Message")}
          errorMessage={messageError}
          rows={8}
          isTextarea
          maxLength={2000}
        />
        <DragAndDropInput label={t("Contact.Attachments")} isShell />
        <Checkbox
          isChecked={isContactable}
          onChange={isChecked => setIsContactable(!isChecked)}
          label={t("Contact.ContactMe")}
        />
        {isContactable && (
          <>
            <TextInput
              value={name}
              onChange={setName}
              label={t("Common.Name")}
              errorMessage={nameError}
              maxLength={40}
            />
            <TextInput
              value={email}
              onChange={setEmail}
              label={t("Common.Email")}
              errorMessage={emailError}
              maxLength={128}
            />
          </>
        )}
        <Button onClick={sendMessage} loading={isSending} size="medium" fullWidth>
          {t("Contact.SendMessage")}
        </Button>
      </Form>
    </Box>
  );
}

Contact.propTypes = {
  className: T.string,
};

export default Contact;
