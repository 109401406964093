import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "commons/util/useToast";
import { Card, TextInputStyled, Title } from "./Authentication.styles";
import ErrorBlock from "commons/components/ErrorBlock";
import styled from "styled-components/macro";
import { isEmailValid } from "commons/util/helpers";
import { CODE_LENGTH, MIN_PASSWORD_LENGTH } from "commons/util/constants";
import Typography from "commons/components/Typography";
import { confirmPasswordReset, initiatePasswordReset } from "commons/util/auth";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import TextInput from "commons/components/TextInput";
import { Trans, useTranslation } from "react-i18next";

const TitleStyled = styled(Title)`
  align-self: flex-start;
  margin-bottom: 0;
`;

const Email = styled.span`
  color: var(--primary-100);
  word-break: break-word;
`;

const VIEWS = {
  EMAIL: "EMAIL",
  CODE: "CODE",
};

function PasswordReset() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [view, setView] = useState(VIEWS[searchParams.get("view")] || VIEWS.EMAIL);
  const [generalError, setGeneralError] = useState(""); // to-do: translations
  const [isProcessing, setIsProcessing] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    setGeneralError("");
    setEmailError("");
    setPasswordError("");
    setCodeError("");
    setPassword("");
    setCode("");
  }, [view]);

  useEffect(() => {
    if (emailError) setEmailError("");
  }, [email]);

  useEffect(() => {
    if (passwordError) setPasswordError("");
  }, [password]);

  useEffect(() => {
    if (codeError) setCodeError("");
  }, [code]);

  function validateEmail() {
    if (!isEmailValid(email)) {
      setEmailError(t("Authentication.InvalidEmail"));
      return false;
    }
    return true;
  }

  function validatePassword() {
    if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(t("Authentication.InvalidPassword", { length: MIN_PASSWORD_LENGTH }));
      return false;
    }
    return true;
  }

  function validateCode() {
    if (!code) {
      setCodeError(t("Authentication.EmptyCode"));
      return false;
    }
    if (code.length !== CODE_LENGTH) {
      setCodeError(t("Authentication.InvalidCode", { length: CODE_LENGTH }));
      return false;
    }
    return true;
  }

  async function handleInitiatePasswordReset() {
    setGeneralError("");
    const isValidEmail = validateEmail();

    if (isValidEmail) {
      setIsProcessing(true);
      try {
        await initiatePasswordReset(email);
        setView(VIEWS.CODE);
        setIsProcessing(false);
      } catch (e) {
        setGeneralError(e.message);
        setIsProcessing(false);
      }
    }
  }

  async function handleResetPassword() {
    setGeneralError("");
    const isCodeValid = validateCode();
    const isPasswordValid = validatePassword();

    if (isCodeValid && isPasswordValid) {
      setIsProcessing(true);
      try {
        await confirmPasswordReset(email, code, password);
        setIsProcessing(false);
        showToast(t("Authentication.PasswordResetSuccess"));
        navigate("/auth/login");
      } catch (e) {
        setGeneralError(e.message);
        setIsProcessing(false);
      }
    }
  }

  return (
    <Card flexDirection="column">
      <Flexbox flexDirection="column" alignItems="flex-end" gap={24}>
        {view === VIEWS.EMAIL && (
          <>
            <TitleStyled>{t("Authentication.ForgotPassword")}</TitleStyled>
            <ErrorBlock>{generalError}</ErrorBlock>
            <Typography>
              <Trans>{t("Authentication.ForgotPasswordInfoBlock")}</Trans>
            </Typography>
            <TextInputStyled
              type="email"
              label={t("Authentication.Email")}
              value={email}
              onChange={setEmail}
              onConfirm={handleInitiatePasswordReset}
              onBlur={validateEmail}
              errorMessage={emailError}
              autoFocus
            />
            <Flexbox gap={8}>
              <Button size="medium" variant="tertiary" link="/auth/login">
                {t("Common.Back")}
              </Button>
              <Button size="medium" onClick={handleInitiatePasswordReset} loading={isProcessing} fullWidth>
                {t("Authentication.SendResetCode")}
              </Button>
            </Flexbox>
          </>
        )}
        {view === VIEWS.CODE && (
          <>
            <TitleStyled>{t("Authentication.ResetYourPassword")}</TitleStyled>
            <ErrorBlock>{generalError}</ErrorBlock>
            <Typography>
              <Trans i18nKey="Authentication.CheckYourEmailAndCreatePassword" values={{ length: CODE_LENGTH, email }}>
                Please check your email. X-digit code has been sent to
                <Email>{{ email }}</Email>.
                <br />
                <br />
                Then, create a new password.
              </Trans>
            </Typography>
            <TextInput
              label={t("Authentication.Code")}
              value={code}
              onChange={setCode}
              onConfirm={handleResetPassword}
              onBlur={validateCode}
              errorMessage={codeError}
              placeholder={t("Authentication.EnterCode")}
              pattern="\d*"
              autoFocus
              maxLength={10}
            />
            <TextInput
              type={isPasswordVisible ? "text" : "password"}
              label={t("Authentication.NewPassword")}
              value={password}
              onChange={setPassword}
              onConfirm={handleResetPassword}
              onBlur={validatePassword}
              errorMessage={passwordError}
              leftHint={t("Authentication.MinimumChars", { length: MIN_PASSWORD_LENGTH })}
              rightIcon={isPasswordVisible ? "visibility" : "visibility_off"}
              onRightIconClick={() => setIsPasswordVisible(isVisible => !isVisible)}
            />
            <Flexbox gap={8}>
              <Button size="medium" variant="tertiary" onClick={() => setView(VIEWS.EMAIL)}>
                {t("Common.Back")}
              </Button>
              <Button size="medium" onClick={handleResetPassword} loading={isProcessing} fullWidth>
                {t("Authentication.ResetPassword")}
              </Button>
            </Flexbox>
          </>
        )}
      </Flexbox>
    </Card>
  );
}

export default PasswordReset;
