import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import T from "prop-types";
import callApi from "commons/util/callApi";
import callLocalStorage from "commons/util/callLocalStorage";
import { useUpdateEffect } from "commons/util/useUpdateEffect";

export const MealPlanContext = createContext(null);
export const MealPlanDispatchContext = createContext(null);

export function useMealPlan() {
  return useContext(MealPlanContext);
}

export function useMealPlanDispatch() {
  return useContext(MealPlanDispatchContext);
}

export function MealPlanProvider({ children }) {
  const [mealPlan, dispatch] = useReducer(mealPlanReducer, initialMealPlan);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchMealPlan() {
      try {
        // const res = await callApi("mealPlan", "get");
        const res = callLocalStorage("mealPlan", "get");
        dispatch({
          type: "INIT",
          mealPlan: res.mealPlan?.map(meal => ({ ...meal, id: +meal.id, date: new Date(meal.date) })) || [],
        });
      } finally {
        setIsLoading(false);
      }
    }
    fetchMealPlan();
  }, []);

  useUpdateEffect(() => {
    callLocalStorage("mealPlan", "set", { mealPlan });
  }, [mealPlan]);

  const mealPlanContextValue = {
    mealPlan,
    isLoading,
  };

  return (
    <MealPlanContext.Provider value={mealPlanContextValue}>
      <MealPlanDispatchContext.Provider value={dispatch}>{children}</MealPlanDispatchContext.Provider>
    </MealPlanContext.Provider>
  );
}

MealPlanProvider.propTypes = {
  children: T.oneOfType([T.object, T.string, T.node]),
};

const initialMealPlan = [];

function mealPlanReducer(mealPlan, action) {
  switch (action.type) {
    case "INIT": {
      return action.mealPlan;
    }
    case "UPDATE": {
      return action.mealPlan;
    }
    case "ADD": {
      return [...mealPlan, action.mealItem];
    }
    case "REMOVE": {
      return mealPlan.filter(m => m.id !== action.mealItemId);
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
