import React from "react";
import styled from "styled-components/macro";
import T from "prop-types";
import Tag from "commons/components/Tag";
import Icon from "commons/components/Icon";
import Image from "commons/components/Image";
import Popover from "commons/components/Popover";
import Flexbox from "commons/components/Flexbox";
import { Link } from "react-router-dom";
import ActionList from "commons/components/ActionList";
import Typography from "commons/components/Typography";
import { useRecipeActions } from "App/Recipes/useRecipeActions";
import { formatTime, shortenLink } from "commons/util/helpers";
import ClampedTextWithTooltip from "commons/components/ClampedTextWithTooltip";

const LinkBox = styled(Link)`
  border: 1px solid var(--neutral-180);
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--neutral-200);
  border: 1px solid var(--neutral-180);
  padding: 8px;
  gap: 16px;
  cursor: pointer;
  margin-top: -1px;

  &:hover {
    background-color: var(--neutral-190);
  }
`;

const Col = styled.div`
  width: ${({ width }) => width && width};
`;

const TypographyStyled = styled(Typography)`
  /* display: inline-block; */
  position: relative;
`;

const CustomButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  width: 24px;
  height: 24px;
  color: var(--neutral-120);
  background: var(--neutral-200);
  box-shadow: var(--shadow-0);
  border-radius: 50%;

  &:hover,
  &:focus-visible {
    color: var(--neutral-100);
    box-shadow: var(--shadow-2);
  }
`;

const RecipeImage = styled(Image)`
  border-radius: var(--border-radius-2);
`;

const ExtraTag = styled(Tag)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

const MAX_NUMBER_OF_TAGS = 13;

function RecipeRow({
  className,
  recipeId,
  title,
  activeTime,
  totalTime,
  source,
  imageURL,
  tags = [],
  isFavorite,
  link,
}) {
  const { recipeActions, toggleIsFavorite } = useRecipeActions(recipeId);

  return (
    <LinkBox className={className} $isFavorite={isFavorite} tabIndex="0" to={link}>
      <RecipeImage src={imageURL} alt={title} width={"64px"} height={"64px"} isRemote />
      <Col width="40%">
        <TypographyStyled variant="h4">
          <ClampedTextWithTooltip maxLines={2}>{title}</ClampedTextWithTooltip>
        </TypographyStyled>
        {!!tags.length && (
          <Flexbox flexWrap="wrap" gap={4} marginTop={4}>
            {tags.slice(0, MAX_NUMBER_OF_TAGS).map(tag => {
              return <Tag key={tag.id} icon={tag.icon} label={tag.label} color={tag.color} isCompact />;
            })}
            {tags.length > MAX_NUMBER_OF_TAGS && (
              <ExtraTag label={`+${tags.length - MAX_NUMBER_OF_TAGS}`} color="grey" isCompact />
            )}
          </Flexbox>
        )}
      </Col>

      <Col width="12%">{formatTime(activeTime)}</Col>
      <Col width="12%">{formatTime(totalTime)}</Col>
      <Col width="calc(36% - 64px - 24px - 5 * 16px)">
        <ClampedTextWithTooltip>{shortenLink(source) || "-"}</ClampedTextWithTooltip>
      </Col>

      <Flexbox flexDirection="column" gap={4} marginLeft="auto">
        <Popover
          trigger={
            <CustomButton>
              <Icon size={16} name="more_vert" />
            </CustomButton>
          }
          content={closePopover => <ActionList actions={recipeActions} closePopover={closePopover} />}
          placement="bottom-end"
        />
        <CustomButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            toggleIsFavorite();
          }}
        >
          <Icon size={16} name="favorite" color={isFavorite ? "red-100" : ""} isFilled={isFavorite} />
        </CustomButton>
      </Flexbox>
    </LinkBox>
  );
}

RecipeRow.propTypes = {
  className: T.string,
  recipeId: T.string,
  title: T.string,
  activeTime: T.number,
  totalTime: T.number,
  source: T.string,
  imageURL: T.string,
  tags: T.array,
  isFavorite: T.bool,
  link: T.string,
};

export default RecipeRow;
